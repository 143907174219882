import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "../shared/services/environment.service";
import { Vertrauensmitarbeiter } from "../shared/models/vertrauensmitarbeiter.model";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class VertrauensmitarbeiterService {
  vertrauensmitarbeiter: Vertrauensmitarbeiter[];

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  public ermittleVertrauensmitarbeiter(): Promise<Vertrauensmitarbeiter[]> {
    if (this.vertrauensmitarbeiter) {
      return Promise.resolve(this.vertrauensmitarbeiter);
    } else {
      return this.httpClient
        .get<Vertrauensmitarbeiter[]>(`${this.environmentService.getBackendUrl()}/vertrauensmaenner`)
        .pipe(
          tap((vertrauensmitarbeiterListe) => {
            this.vertrauensmitarbeiter = vertrauensmitarbeiterListe;
          }),
        )
        .toPromise();
    }
  }
}
