import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { VertragDetails } from "../../dashboard/models/vertragdetails.model";
import {
  AenderbareVertragsdatenUnfall,
  Gliedertaxe,
  Rentenvariante,
} from "../../dashboard/models/aenderbareVertragsdatenUnfall";
import { VersandwegEnum } from "../../dashboard/models/versandwegEnum";
import { GrundsummeMin, UnfallRenteMinMaxEnum } from "../../dashboard/models/grundsumme_unfallRente.enums";
import { ProgressionEnum } from "../../dashboard/models/progression.enum";
import { FormcontrolsEnum } from "../../dashboard/models/formcontrols.enum";
import { BeitragErmittelnService } from "./beitrag-ermitteln.service";
import { validateMobilePhoneNumberLength, validateMobilePhoneNumberPattern } from "../custom-validators";

@Injectable({
  providedIn: "root",
})
export class FormsService {
  private form: UntypedFormGroup;

  constructor(
    private beitragErmittelnService: BeitragErmittelnService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.initForm();
  }

  initForm() {
    return this.formBuilder.group({
      detailsForm: this.formBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        telefon: ["", [Validators.required, validateMobilePhoneNumberLength(), validateMobilePhoneNumberPattern()]],
        flexN: [],
        versandweg: [],
        vertrauensmitarbeiterIn: [],
        anschreiben: [],
      }),
      angebotBearbeitenForm: this.buildAngebotBearbeitenForm(),
    });
  }

  setFormValues(vertragdetails: VertragDetails) {
    let email = "";
    let telefon = "";
    if (
      vertragdetails.vertrag.versicherungsnehmer?.emails &&
      vertragdetails.vertrag.versicherungsnehmer?.emails.length > 0
    ) {
      email = vertragdetails.vertrag.versicherungsnehmer.emails[0];
    }
    if (
      vertragdetails.vertrag.versicherungsnehmer?.telefonnummern &&
      vertragdetails.vertrag.versicherungsnehmer?.telefonnummern.length > 0
    ) {
      telefon = vertragdetails.vertrag.versicherungsnehmer.telefonnummern[0];
    }
    this.form.patchValue({
      detailsForm: {
        email,
        telefon,
        versandweg: VersandwegEnum.EMAIL,
      },
      angebotBearbeitenForm: {
        [FormcontrolsEnum.MIT_NEUWERTSCHAEDIGUNG]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenHaftpflicht?.neuwertentschaedigungEigeneSachen,
        ),
        [FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_FUER_ZWEITWOHNUNG]:
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat
            ?.versicherungssummeHausratFuerZweitwohnungInStaendigBewohntemGebaeude,
        [FormcontrolsEnum.HAUSRAT_FUER_ZWEITWOHNUNG]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.hausratFuerZweitwohnungStaendigBewohntesGebaeude,
        ),
        [FormcontrolsEnum.KONTOSCHUTZBRIEF]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.kontoFinanzschutzbrief,
        ),
        [FormcontrolsEnum.HAUSRAT_ANGEHOERIGEN]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.hausratAngehoerigeSeniorenheim,
        ),
        [FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_ANGEHOERIGE]:
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.versicherungssummeHausratAngehoerigeSeniorenheim,
        [FormcontrolsEnum.HAUSRATOPTIMAL_XXL]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.hausratOptimalXxl,
        ),
        [FormcontrolsEnum.FAHRRAD_PLUS]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.fahrradPlus,
        ),
        [FormcontrolsEnum.FREIZEIT_PLUS]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.freizeitPlus,
        ),
        [FormcontrolsEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS]:
          vertragdetails.vertrag.aenderbareVertragsdatenHausrat?.versicherungssummeFreizeitPlus,
        [FormcontrolsEnum.REISEGEPAECK]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenReise?.reisegepaeck,
        ),
        [FormcontrolsEnum.VERSICHERUNGSSUMME_REISEGEPAECK]:
          vertragdetails.vertrag.aenderbareVertragsdatenReise?.versicherungssummeReisegepaeck,
        [FormcontrolsEnum.REISERUECKTRITT]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenReise?.reiseruecktritt,
        ),
        [FormcontrolsEnum.VERSICHERUNGSSUMME_REISERUECKTRITT]:
          vertragdetails.vertrag.aenderbareVertragsdatenReise?.versicherungssummeReiseruecktritt,
        [FormcontrolsEnum.REISEASSISTANCELEISTUNGEN]: this.booleanToYesNo(
          vertragdetails.vertrag.aenderbareVertragsdatenReise?.reiseAssistanceleistungenInklAuslandsreisekranken,
        ),
        [FormcontrolsEnum.GRUNDFLAECHE]: vertragdetails.vertrag.aenderbareVertragsdatenGlas?.grundflaeche,
      },
    });
  }

  getDetailsForm(): UntypedFormGroup {
    const key = "detailsForm";
    return this.form.controls[key] as UntypedFormGroup;
  }

  getAngebotBearbeitenForms(): UntypedFormGroup {
    const key = "angebotBearbeitenForm";
    return this.form.controls[key] as UntypedFormGroup;
  }

  buildAngebotBearbeitenForm() {
    return this.formBuilder.group({
      [FormcontrolsEnum.MIT_NEUWERTSCHAEDIGUNG]: [],
      [FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_FUER_ZWEITWOHNUNG]: [],
      [FormcontrolsEnum.HAUSRAT_FUER_ZWEITWOHNUNG]: [],
      [FormcontrolsEnum.KONTOSCHUTZBRIEF]: [],
      [FormcontrolsEnum.HAUSRAT_ANGEHOERIGEN]: [],
      [FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_ANGEHOERIGE]: [],
      [FormcontrolsEnum.HAUSRATOPTIMAL_XXL]: [],
      [FormcontrolsEnum.FREIZEIT_PLUS]: [],
      [FormcontrolsEnum.FAHRRAD_PLUS]: [],
      [FormcontrolsEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS]: [],
      [FormcontrolsEnum.REISEGEPAECK]: [],
      [FormcontrolsEnum.VERSICHERUNGSSUMME_REISEGEPAECK]: [],
      [FormcontrolsEnum.REISERUECKTRITT]: [],
      [FormcontrolsEnum.VERSICHERUNGSSUMME_REISERUECKTRITT]: [],
      [FormcontrolsEnum.REISEASSISTANCELEISTUNGEN]: [],
      [FormcontrolsEnum.GRUNDFLAECHE]: ["", Validators.required],
      aenderbareVertragsdatenUnfall: this.formBuilder.array([]),
    });
  }

  public resetForm(): void {
    this.form.reset();
  }

  // Set form controls for anderbareVertragsdatenUnfall according to number of elements in anderbareVertragsdatenUnfall array
  public setaenderbareVertragsdatenUnfall(vertragdetails: VertragDetails) {
    if (vertragdetails.vertrag.aenderbareVertragsdatenUnfall.length > 0) {
      //TODO: Error Handling if no progression available ???
      vertragdetails.vertrag.aenderbareVertragsdatenUnfall.forEach((unfall, index) => {
        const progression = this.beitragErmittelnService.getProgressionForPerson(unfall.versichertePerson);
        (
          (this.form.controls.angebotBearbeitenForm as UntypedFormGroup).controls
            .aenderbareVertragsdatenUnfall as UntypedFormArray
        ).setControl(
          index,
          this.formBuilder.group({
            [FormcontrolsEnum.INVALIDITAETSGRUNDSUUME]: [
              this.getInvaliditaetsGrundsumme(unfall.invaliditaetsGrundsumme, progression),
            ],
            [FormcontrolsEnum.UNFALLRENTE]: [this.getUnfallRente(unfall.unfallRente)],
            [FormcontrolsEnum.UNFALLRENTENVARIANTE]: [
              unfall.unfallRentenvariante !== null
                ? unfall.unfallRentenvariante === Rentenvariante.AB_50_EINMAL_AB_75_ZWEIMAL
                  ? "ab 50% 1x, 75% 2x"
                  : "ab 50% 1x, 75% 2x, 90% 3x"
                : null,
            ],
            [FormcontrolsEnum.GLIEDERTAXE]: [
              unfall.gliedertaxe === Gliedertaxe.VORHANDEN ||
              unfall.gliedertaxe === null ||
              unfall.gliedertaxe === undefined
                ? "Standard"
                : "Verbessert",
            ],
          }),
        );
      });
    }
  }

  public patchInvaliditaetsgrundsummen(aenderbareVertragsdatenUnfall: AenderbareVertragsdatenUnfall[]) {
    if (
      aenderbareVertragsdatenUnfall.length > 0 &&
      (
        (this.form.controls.angebotBearbeitenForm as UntypedFormGroup).controls
          .aenderbareVertragsdatenUnfall as UntypedFormArray
      ).length > 0
    ) {
      aenderbareVertragsdatenUnfall.forEach((unfall, index) => {
        if (
          (
            this.getAngebotBearbeitenForms().controls[
              FormcontrolsEnum.AENDERBAREVERTRAGSDATEN_UNFALL
            ] as UntypedFormArray
          ).at(index) !== undefined
        ) {
          (
            (this.form.controls.angebotBearbeitenForm as UntypedFormGroup).controls
              .aenderbareVertragsdatenUnfall as UntypedFormArray
          )
            .at(index)
            .patchValue({
              invaliditaetsGrundsumme: unfall.invaliditaetsGrundsumme,
            });
        } else {
          console.log("control undefined");
        }
      });
    }
  }

  /**
   * Takes a FormGroup and returns a list of all FormControlNames, that are required (Validator.required is set as a validator function)
   *
   * @param formGroup
   */
  public getRequiredControls(formGroup: UntypedFormGroup): string[] {
    if (!formGroup) {
      return [];
    }
    return Object.entries(formGroup.controls)
      .map((entry) => {
        const [name, control] = entry;
        const validator = control.validator ? control.validator(new UntypedFormControl()) : null;
        return validator && validator.hasOwnProperty("required") ? name : null;
      })
      .filter((entry) => entry !== null);
  }

  public getUnfallRente(unfallRente: number): number {
    if (unfallRente > UnfallRenteMinMaxEnum.MIN && unfallRente < UnfallRenteMinMaxEnum.MAX) {
      return unfallRente;
    } else if (unfallRente <= UnfallRenteMinMaxEnum.MIN && unfallRente !== null && unfallRente !== 0) {
      return UnfallRenteMinMaxEnum.MIN;
    } else if (unfallRente >= UnfallRenteMinMaxEnum.MAX) {
      return UnfallRenteMinMaxEnum.MAX;
    } else if (unfallRente === null || unfallRente === 0) {
      return unfallRente;
    }
  }

  public getInvaliditaetsGrundsumme(grundsumme: number, progression: ProgressionEnum) {
    switch (progression) {
      case ProgressionEnum.PROGRESSION_225:
        return grundsumme > GrundsummeMin.PROGRESSION_225 ? grundsumme : GrundsummeMin.PROGRESSION_225;
      case ProgressionEnum.PROGRESSION_1000:
        return grundsumme > GrundsummeMin.PROGRESSION_1000 ? grundsumme : GrundsummeMin.PROGRESSION_1000;
      case ProgressionEnum.PROGRESSION_OHNE:
        return grundsumme > GrundsummeMin.PROGRESSION_OHNE ? grundsumme : GrundsummeMin.PROGRESSION_OHNE;
      default:
        console.log(`${progression} unbekannt`);
        break;
    }
  }

  private booleanToYesNo(val: boolean): "yes" | "no" {
    return val ? "yes" : "no";
  }
}
