export interface AenderbareVertragsdatenGlas {
  glasKunststoffScheibenGewaechshausAufVersicherungsgrunstueck?: boolean;
  grundflaeche?: GrundflaecheEnum;
}

export enum GrundflaecheEnum {
  GRUNDFLAECHE_5 = "GRUNDFLAECHE_5",
  GRUNDFLAECHE_10 = "GRUNDFLAECHE_10",
  GRUNDFLAECHE_15 = "GRUNDFLAECHE_15",
}
