import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  getBackendUrl(): string {
    return environment.backendUrl;
  }

  getKeycloakUrl(): string {
    return environment.keycloakConfig.url;
  }

  getZobUrl(): string {
    return environment.zobUrl;
  }

  getUmstellungsfaehigkeitsAbschaltungIsErlaubt(): boolean {
    return environment.umstellungsfaehigkeitsAbschaltungIsErlaubt;
  }
}
