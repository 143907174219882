export enum SortOptions {
  NAME = "name",
  VORNAME = "vorname",
  TARIF = "tarifgeneration",
  ABLAUFDATUM = "ablaufdatum",
  UMSTELLUNGSFAEHIGKEIT = "umstellungsfaehigkeit",
  VERSENDET_AM = "versendetAm",
  ANGENOMMEN_AM = "angenommenAm",
  VERSANDWEG = "versandweg",
  UMSTELLUNGSSTATUS = "umstellungsstatus",
}
