import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { ZobUser } from "../../shared/models/zob-user.model";
import { UserService } from "../../shared/services/user.service";
import { tap } from "rxjs/operators";
import { Benutzertyp } from "../../shared/models/benutzertyp.enum";

@Injectable({
  providedIn: "root",
})
export class ZobUserResolver implements Resolve<ZobUser> {
  constructor(private userService: UserService) {}
  resolve(): Observable<ZobUser> {
    const currentZobUser = this.userService.getZobUser();

    if (currentZobUser) {
      return of(currentZobUser);
    } else {
      return this.userService.fetchZobUser().pipe(
        tap((zobUser) => {
          if (zobUser.benutzertyp === Benutzertyp.MITBENUTZER) {
            this.userService.fetchZobContextUser();
          }
        }),
      );
    }
  }
}
