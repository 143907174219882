import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent {
  @Input()
  isVisible = false;

  @Input()
  title = "Titel";

  @Output()
  isVisibleChange = new EventEmitter<boolean>();

  // function to close the modal
  close() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
