import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { AlertInterface } from "./shared/models/alert.models";
import { DsAlert } from "@dvag/design-system-angular";
import { AlertService } from "./shared/services/alert.service";
import { UserService } from "./shared/services/user.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild("genericAlert") alert: DsAlert;
  alertContent: AlertInterface;
  showAppFrame$: Observable<boolean>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.showAppFrame$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route) => route.data),
      map((data) => (data.hasOwnProperty("showNoFrame") ? !data.showNoFrame : true)),
    );

    this.userService.fetchZobUser();
  }

  ngAfterViewInit() {
    this.alertService.setAlertRef(this.alert, this.setAlertContent.bind(this));
  }

  private setAlertContent(alertContent: AlertInterface) {
    this.alertContent = alertContent;
  }
}
