import { Injectable } from "@angular/core";
import { AlertInterface, BtnActions } from "../models/alert.models";
import { TEXTBLOCKS } from "../../vertrag-details/text-blocks";
import { DsAlert } from "@dvag/design-system-angular";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private alertTextBlocks = TEXTBLOCKS.alert;
  private alertRef: DsAlert;
  private setContentCallback: (alertContent: AlertInterface) => void;

  openAfterAngebotVerwerfenAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.angebotVerwerfen, btnActions);
  }

  openAngebotVersendenEmailAlert(emailAddress: string, phoneNumber: string, btnActions: BtnActions) {
    const versendenText = { ...this.alertTextBlocks.versendenPerEmail };
    versendenText.body = versendenText.body
      .replace("<EMAIL-INPUT>", emailAddress)
      .replace("<PHONE-INPUT>", phoneNumber);
    this.generateAlert(versendenText, { okAction: btnActions.okAction, abortAction: btnActions.abortAction });
  }

  openAngebotVersendenPostAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.versendenPerPost, { okAction: btnActions.okAction });
  }

  openAngebotVersendetEmailAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.angebotVersendetEmail, {
      okAction: btnActions.okAction,
      abortAction: btnActions.abortAction,
    });
  }

  openAngebotVersendetPostAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.angebotVersendetPost, {
      okAction: btnActions.okAction,
      abortAction: btnActions.abortAction,
    });
  }

  openBearbeitenAbbrechenAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.bearbeitenAbbrechen, btnActions);
  }

  openDosNotInstalledAlert(): void {
    this.generateAlert(this.alertTextBlocks.dosNotInstalled);
  }

  openEweEmailAlert(sendToEmail: string, btnActions: BtnActions): void {
    const alertOptions = { ...this.alertTextBlocks.eweEmailAlert };
    alertOptions.body = this.insertEmailAddress(sendToEmail, alertOptions.body);
    this.generateAlert(alertOptions, {
      okAction: btnActions.okAction,
    });
  }

  openEwePostAlert(sendToEmail: string, btnActions: BtnActions): void {
    const alertOptions = { ...this.alertTextBlocks.ewePostAlert };
    alertOptions.body = this.insertEmailAddress(sendToEmail, alertOptions.body);
    this.generateAlert(alertOptions, {
      okAction: btnActions.okAction,
    });
  }

  openEweVersendenAlert(sendToEmail: string, btnActions: BtnActions): void {
    const alertOptions = { ...this.alertTextBlocks.eweVersendenAlert };
    alertOptions.body = this.insertEmailAddress(sendToEmail, alertOptions.body);
    this.generateAlert(alertOptions, {
      okAction: btnActions.okAction,
    });
  }

  openEweVersendenErfolgreichAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.eweVersendenErfolgreich, {
      okAction: btnActions.okAction,
      abortAction: btnActions.abortAction,
    });
  }

  openMaxFlexNUeberschrittenAlert(): void {
    this.generateAlert(this.alertTextBlocks.maxFlexNUeberschritten);
  }

  openMindereitragBeseitigenAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.minderbeitragBeseitigen, { okAction: btnActions.okAction });
  }

  openPflichtfelderAusfüllenAlert(): void {
    this.generateAlert(this.alertTextBlocks.pflichtfelderAusfuellen);
  }

  openUmstellungVerwerfenAlert(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.umstellungVerwerfen, btnActions);
  }

  openVersandAbbrechen(btnActions: BtnActions): void {
    this.generateAlert(this.alertTextBlocks.versandAbbrechen, btnActions);
  }

  openVersandVerhindernMinderbeitragAlert(): void {
    this.generateAlert(this.alertTextBlocks.versandVerhindernMinderbeitrag);
  }

  openVersandVerhindernNichtBerechenbarAlert(): void {
    this.generateAlert(this.alertTextBlocks.versandVerhindernNichtBerechenbar);
  }

  setAlertRef(alertRef: DsAlert, setContentCallback: (alertContent: AlertInterface) => void) {
    this.alertRef = alertRef;
    this.setContentCallback = setContentCallback;
  }

  private generateAlert(alertTextBlocks, btnActions?: BtnActions): void {
    const okAction = btnActions?.okAction ?? this.defaultAlertAction.bind(this);
    const abortAction = btnActions?.abortAction ?? this.defaultAlertAction.bind(this);
    const alertContent: AlertInterface = {
      ...alertTextBlocks,
      btnOkAction: okAction,
      btnAbortAction: abortAction,
    };
    this.setContentCallback(alertContent);
    this.alertRef.visible = true;
  }

  private defaultAlertAction() {
    this.alertRef.visible = false;
  }

  private insertEmailAddress(emailAddress: string, text: string): string {
    return text.replace("<EMAIL-INPUT>", emailAddress);
  }
}
