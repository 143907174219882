import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { VertragDetailsComponent } from "./vertrag-details/vertrag-details.component";
import { AngebotBearbeitenComponent } from "./angebot-bearbeiten/angebot-bearbeiten.component";
import { ErrorPageComponent } from "./shared/error-page/error-page.component";
import { PermissionGuard } from "./permission.guard";
import { DsFullscreenSpinner } from "@dvag/design-system-angular";
import { ZobUserResolver } from "./dashboard/resolver/zob-user.resolver";

const routes: Routes = [
  {
    path: "",
    resolve: {
      zobUser: ZobUserResolver,
    },
    children: [
      {
        path: "",
        loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "detailinformationen",
        component: VertragDetailsComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: "angebotbearbeiten",
        component: AngebotBearbeitenComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: "fehlermeldung",
        component: ErrorPageComponent,
      },
      {
        path: "pdfSpinner",
        component: DsFullscreenSpinner,
        data: { showNoFrame: true },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
