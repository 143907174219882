import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { KeycloakService } from "../services/keycloak.service";
import { mergeMap } from "rxjs/operators";
import { EnvironmentService } from "../services/environment.service";
import { AssistentService } from "../services/assistent.service";
import { UserService } from "../services/user.service";

@Injectable()
export class KeycloakBearerInterceptor implements HttpInterceptor {
  constructor(
    private environment: EnvironmentService,
    private keycloak: KeycloakService,
    private userService: UserService,
    private assistentService: AssistentService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const keycloak = this.keycloak.keycloakInstance;

    if (!this.isCallToBackend(req) || !keycloak.authenticated) {
      return next.handle(req);
    }

    if (
      this.assistentService.getSelectedHauptbenutzerId().length > 0 &&
      !req.url.startsWith(this.environment.getZobUrl())
    ) {
      return from(this.keycloak.updateToken()).pipe(
        mergeMap((token) => {
          return next.handle(
            req.clone({
              setHeaders: {
                Authorization: "Bearer " + token,
                "context-user": this.assistentService.getSelectedHauptbenutzerId(),
              },
            }),
          );
        }),
      );
    }

    return from(this.keycloak.updateToken()).pipe(
      mergeMap((token) => {
        return next.handle(req.clone({ setHeaders: { Authorization: "Bearer " + token } }));
      }),
    );
  }

  private isCallToBackend(req: HttpRequest<any>): boolean {
    return (
      req.url.startsWith(this.environment.getBackendUrl()) ||
      req.url.startsWith(this.environment.getKeycloakUrl()) ||
      req.url.startsWith(this.environment.getZobUrl())
    );
  }
}
