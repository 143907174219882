const ZUR_STARTSEITE_TEXT = "Zur Startseite";
const NAECHSTE_UMSTELLUNG_TEXT = "Nächste Umstellung";
const OK_TEXT = "OK";
const ABBRECHEN_TEXT = "Abbrechen";

export const TEXTBLOCKS = {
  info: {
    email:
      "Bei per E-Mail versendeten Umstellungsangeboten wird diese E-Mail-Adresse für die Authentifizierung benötigt.",
    phone:
      "Bei per E-Mail versendeten Umstellungsangeboten wird diese Mobilfunknummer für die Authentifizierung benötigt.",
    ewePartial:
      "Ohne Einwilligung in die Werbung per E-Mail ist der Versand des Umstellungsangebotes nur postalisch möglich. Sie können alternativ die EWE und Werbeeinwilligungen erneut elektronisch einholen und diese Umstellung später bearbeiten.",
    eweMissing:
      "Nur mit Einwilligungserklärung können Umstellungsangebote versendet werden. Holen Sie die EWE jetzt elektronisch ein und bearbeiten Sie diese Umstellung später.",
    versandEmail:
      "Beim E-Mail-Versand wird ein E-Mail-Anschreiben mit einem Link zu einer Webseite verschickt, auf der nach erfolgter Authentifizierung via E-Mail-Adresse und Mobilfunknummer das Umstellungsangebot eingesehen und heruntergeladen werden kann. Die Annahme des Angebots erfolgt über eine elektronische Bestätigung auf der Webseite.",
    versandPost:
      "Beim postalischen Versand wird das Umstellungsangebot inklusive Anschreiben und Antwortkarte über den zentralen Postversand der Generali zugestellt. Die Annahme des Angebots erfolgt über die postalische Rücksendung der unterschriebenen Antwortkarte.",
  },
  alert: {
    angebotVersendetEmail: {
      id: "versenden-successful",
      title: "Angebot versendet",
      body: `Aus rechtlichen Gründen werden die Umstellungsangebote erst nach erfolgtem Beratungscheck, spätestens nach Ablauf der 4-Tages-Frist, tatsächlich an Ihre Kunden versendet und behalten solange den Status "Warte auf Übertragung".
      <br><br>
        Was möchten Sie als Nächstes tun?`,
      btnAbortLabel: ZUR_STARTSEITE_TEXT,
      btnOkLabel: NAECHSTE_UMSTELLUNG_TEXT,
    },
    angebotVersendetPost: {
      id: "versenden-successful",
      title: "Angebot versendet",
      body: `Aus rechtlichen Gründen werden die Umstellungsangebote erst nach Ablauf der 4-Tages-Frist tatsächlich an Ihre Kunden versendet.
             <br><br>Was möchten Sie als Nächstes tun?`,
      btnAbortLabel: ZUR_STARTSEITE_TEXT,
      btnOkLabel: NAECHSTE_UMSTELLUNG_TEXT,
    },
    angebotVerwerfen: {
      body: "Was möchten Sie als Nächstes tun?",
      btnAbortLabel: "Zur Startseite",
      btnOkLabel: "Nächste Umstellung",
      id: "angebotverworfen",
      title: "Angebot verworfen",
    },
    bearbeitenAbbrechen: {
      id: "bearbeiten-abbrechen",
      title: "Das Angebot wurde noch nicht versendet",
      body: "Möchten Sie die Seite verlassen? Ihre bisherige Bearbeitung wird so zurückgesetzt.",
      btnAbortLabel: "Bearbeitung fortsetzen",
      btnOkLabel: "Seite verlassen",
    },
    dosNotInstalled: {
      id: "no-dos-installed",
      title: "Um diese Funktion nutzen zu können, muss das DVAG Online-System installiert sein.",
      btnAbortLabel: OK_TEXT,
    },
    eweEmailAlert: {
      id: "ewe-email-alert",
      title: "Werbeeinwilligung E-Mail fehlt",
      body: `Der E-Mail-Versand des Umstellungsangebotes ist nicht möglich, da keine Einwilligung in die Werbung per E-Mail
             vorliegt. Wollen Sie erneut eine EWE inklusive Werbeeinwilligung einholen und eine E-Mail mit persönlichem Link zur
             Einwilligung in die Datenverarbeitung an <EMAIL-INPUT> versenden? Alternativ können Sie das
             Umstellungsangebot auch postalisch versenden.`,
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "EWE einholen",
    },
    ewePostAlert: {
      id: "ewe-post-alert",
      title: "Einwilligungserklärung fehlt",
      body: `Der Versand des Umstellungsangebotes ist nicht möglich, da keine Einwilligung in die Datenverarbeitung vorliegt.
             Wollen Sie die EWE einholen und eine E-Mail mit persönlichem Link zur Einwilligung in die Datenverarbeitung an
             <EMAIL-INPUT> versenden?`,
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "EWE einholen",
    },
    eweVersendenAlert: {
      id: "ewe-versenden-alert",
      title: "EWE einholen?",
      body: `Soll die E-Mail mit persönlichem Link zur Einwilligung in die Datenverarbeitung an
      <b><EMAIL-INPUT></b> versendet werden?`,
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "Versenden",
    },
    eweVersendenErfolgreich: {
      id: "ewe-versenden-erfolgreich",
      title: "E-Mail versendet",
      body: `Sobald die Einwilligung in die Datenverarbeitung vorliegt, können Sie diesen Vertrag umstellen.<br>
             Was möchten Sie als Nächstes tun?`,
      btnAbortLabel: ZUR_STARTSEITE_TEXT,
      btnOkLabel: NAECHSTE_UMSTELLUNG_TEXT,
    },
    maxFlexNUeberschritten: {
      id: "max-flexn-ueberschritten",
      title: "Maximaler Nachlass überschritten",
      body: "Der Flexible Nachlass wurde automatisch auf den maximal möglichen Wert gesetzt.",
      btnAbortLabel: OK_TEXT,
    },
    minderbeitragBeseitigen: {
      id: "minderbeitrag-beseitigen",
      title: "Minderbeitrag automatisch beseitigen?",
      body: `So werden die Invaliditätsgrundsummen aller Personen in der Unfallversicherung in Schritten von 500 € erhöht, bis der Minderbeitrag beseitigt ist.
             <br>Alternativ können Sie das Angebot selbst bearbeiten, indem Sie Abbrechen und den Versicherungsumfang manuell erweitern.`,
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "Minderbeitrag beseitigen",
    },
    pflichtfelderAusfuellen: {
      id: "pflichtfelder-ausfuellen",
      title: "Bitte füllen Sie alle Pflichtfelder aus!",
      btnAbortLabel: OK_TEXT,
    },
    umstellungVerwerfen: {
      id: "umstellungverwerfen",
      title: "Umstellung verwerfen?",
      body: `Ihr Vertrag wird in "Verworfene Umstellungen" verschoben und nicht mehr in der Liste der Bestandsverträge angezeigt.`,
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "Verwerfen",
    },
    versandAbbrechen: {
      id: "versand-stoppen",
      title: "Das Angebot wurde noch nicht versendet",
      body: `Wenn das Angebot an den Kunden nicht versendet werden soll, klicken Sie auf „Versand stoppen“.
          Anschließend können Sie in der Liste „Bestandsverträge“ ein neues Umstellungsangebot berechnen. Möchten Sie den Versand stoppen?`,
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "Versand stoppen",
    },
    versandVerhindernMinderbeitrag: {
      id: "zukuenftigerBeitragNichtBerechenbar",
      title: "Keine Berechnung möglich!",
      body: "Der Versand des Umstellungsangebotes ist nicht möglich. Der Minderbeitrag übersteigt 120 €. Daher kann der Vertrag nicht umgestellt werden",
      btnAbortLabel: OK_TEXT,
    },
    versandVerhindernNichtBerechenbar: {
      id: "zukuenftigerBeitragNichtBerechenbar",
      title: "Keine Berechnung möglich!",
      body: "Der Versand des Umstellungsangebotes ist nicht möglich, da kein Beitrag berechnet werden konnte. Bitte beachten Sie die Hinweise zur Bearbeitung",
      btnAbortLabel: OK_TEXT,
    },
    versendenPerEmail: {
      id: "versenden-confirm",
      title: "Angebot per E-Mail versenden?",
      body: "Es wird ein E-Mail-Anschreiben mit Link zum Umstellungsangebot versendet. Die hinterlegte E-Mail-Adresse <b><EMAIL-INPUT></b> und Mobilfunknummer <b><PHONE-INPUT></b> wird für die Authentifizierung benötigt.",
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "Versenden",
    },
    versendenPerPost: {
      id: "versenden-confirm",
      title: "Angebot per Post versenden?",
      body: "Das Umstellungsangebot inklusive Anschreiben und Antwortkarte wird über den zentralen Postversand der Generali versendet.",
      btnAbortLabel: ABBRECHEN_TEXT,
      btnOkLabel: "Versenden",
    },
  },
  error: {
    telefon: "Bitte geben Sie eine gültige Mobilfunknummer ein.",
    email: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  },
};
