export enum Benutzertyp {
  ASSISTENT = "ASSISTENT",
  AUSSENDIENST = "AUSSENDIENST",
  AUTOTEST = "AUTOTEST",
  HOTLINE = "HOTLINE",
  INNENDIENST = "INNENDIENST",
  KONZERN_MITARBEITER = "KONZERN_MITARBEITER",
  MITBENUTZER = "MITBENUTZER",
  SERVICE_ACCOUNT = "SERVICE_ACCOUNT",
}
