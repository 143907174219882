export interface AenderbareVertragsdatenUnfall {
  angebotsart?: string;
  invaliditaetsGrundsumme?: number;
  unfallRente?: number;
  unfallRentenvariante?: string;
  gliedertaxe?: string;
  versichertePerson?: VersichertePerson;
}

export interface VersichertePerson {
  vorname: string;
  name: string;
  geburtsdatum: string;
}

export enum Gliedertaxe {
  VORHANDEN = "GLIEDERTAXE",
  VERBESSERT = "VERBESSERTE_GLIEDERTAXE",
}

export enum Rentenvariante {
  AB_50_EINMAL_AB_75_ZWEIMAL = "AB_50_EINMAL_AB_75_ZWEIMAL",
  AB_50_EINMAL_AB_75_ZWEIMAL_AB_90_DREIMAL = "AB_50_EINMAL_AB_75_ZWEIMAL_AB_90_DREIMAL",
}

export enum Angebotsart {
  UNFALL_AKTIV = "UNFALL_AKTIV",
  INDIVIDUAL = "FREI",
}
