/**
 * suh-bestandsmanagement
 * Mittels dieser Schnittstelle wird der Bestand der Sach-, Unfall und Haftpflichtverträge gepflegt und verwaltet.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum UmstellungsfaehigkeitEnum {
  AUTOMATISCH = "AUTOMATISCH",
  TEILWEISE = "TEILWEISE",
  NICHT = "NICHT",
}
