export interface AenderbareVertragsdatenHausrat {
  weitereElementargefahren?: boolean;
  versicherungssummeHausratFuerZweitwohnungInStaendigBewohntemGebaeude?: VersicherungssummeZweitwohnungEnum;
  hausratFuerZweitwohnungStaendigBewohntesGebaeude?: boolean;
  kontoFinanzschutzbrief?: boolean;
  hausratAngehoerigeSeniorenheim?: boolean;
  versicherungssummeHausratAngehoerigeSeniorenheim?: VersicherungssummeSeniorenheimEnum;
  hausratOptimalXxl?: boolean;
  fahrradPlus?: boolean;
  freizeitPlus?: boolean;
  versicherungssummeFreizeitPlus?: VersicherungssummeFreizeitPlusEnum;
}

export enum VersicherungssummeFreizeitPlusEnum {
  VERSICHERUNGSSUMME_FREIZEIT_PLUS_1000 = "VERSICHERUNGSSUMME_FREIZEIT_PLUS_1000",
  VERSICHERUNGSSUMME_FREIZEIT_PLUS_2000 = "VERSICHERUNGSSUMME_FREIZEIT_PLUS_2000",
  VERSICHERUNGSSUMME_FREIZEIT_PLUS_3000 = "VERSICHERUNGSSUMME_FREIZEIT_PLUS_3000",
  VERSICHERUNGSSUMME_FREIZEIT_PLUS_4000 = "VERSICHERUNGSSUMME_FREIZEIT_PLUS_4000",
}

export enum VersicherungssummeSeniorenheimEnum {
  VERSICHERUNGSSUMME_SENIORENHEIM_10000 = "VERSICHERUNGSSUMME_SENIORENHEIM_10000",
  VERSICHERUNGSSUMME_SENIORENHEIM_15000 = "VERSICHERUNGSSUMME_SENIORENHEIM_15000",
  VERSICHERUNGSSUMME_SENIORENHEIM_20000 = "VERSICHERUNGSSUMME_SENIORENHEIM_20000",
  VERSICHERUNGSSUMME_SENIORENHEIM_25000 = "VERSICHERUNGSSUMME_SENIORENHEIM_25000",
  VERSICHERUNGSSUMME_SENIORENHEIM_30000 = "VERSICHERUNGSSUMME_SENIORENHEIM_30000",
}

export enum VersicherungssummeZweitwohnungEnum {
  VERSICHERUNGSSUMME_ZWEITWOHNUNG_10000 = "VERSICHERUNGSSUMME_ZWEITWOHNUNG_10000",
  VERSICHERUNGSSUMME_ZWEITWOHNUNG_15000 = "VERSICHERUNGSSUMME_ZWEITWOHNUNG_15000",
  VERSICHERUNGSSUMME_ZWEITWOHNUNG_20000 = "VERSICHERUNGSSUMME_ZWEITWOHNUNG_20000",
  VERSICHERUNGSSUMME_ZWEITWOHNUNG_25000 = "VERSICHERUNGSSUMME_ZWEITWOHNUNG_25000",
  VERSICHERUNGSSUMME_ZWEITWOHNUNG_30000 = "VERSICHERUNGSSUMME_ZWEITWOHNUNG_30000",
}
