import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { UmstellungsstatusEnum } from "../models/vertragsumstellungsStatusEnum";
import { VertragsumstellungenService } from "../../shared/services/vertragsumstellungen.service";
import { Vertragsumstellung } from "../models/vertragsumstellung";
import { FilterParametersInterface } from "../models/filter-parameters.model";
import { UmstellungsfaehigkeitEnum } from "../models/vertragsumstellungsfaehigkeitEnum";
import { DeckungsauspraegungEnum } from "../models/deckungsauspraegungEnum";
import { SortOptions } from "../models/sortOptions";
import { DatePipe } from "@angular/common";
import { VertragsdatenFuerAngebot } from "../../shared/models/vertragsdaten-fuer-angebot";
import { map, take, tap } from "rxjs/operators";
import { Vertragsumstellungsfaehigkeit } from "../models/vertragsumstellungsfaehigkeit";

@Injectable({
  providedIn: "root",
})
export class VertraegeService implements OnDestroy {
  private filterParameters: FilterParametersInterface = {
    isUmstellungsfaehig: true,
    isBedingtUmstellungsfaehig: true,
    isPerErsatzantrag: true,
    isAktuellerTarif: false,
    hasVollschutz: false,
    hasReise: false,
    hasNoReise: false,
  };
  private currentSortAscending = true;
  private currentSortAttribute = SortOptions.NAME;

  private currentSearchTerms: string[] = [];

  private allVertragsumstellungen: Vertragsumstellung[];
  private currentVisibleVertragsumstellungen: Vertragsumstellung[];

  private offeneVertragsumstellungen$: BehaviorSubject<Vertragsumstellung[]> = new BehaviorSubject([]);
  private versendeteVertragsumstellungen$: BehaviorSubject<Vertragsumstellung[]> = new BehaviorSubject([]);
  private verworfeneVertragsumstellungen$: BehaviorSubject<Vertragsumstellung[]> = new BehaviorSubject([]);

  private loadingVertraege$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private contractDeleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingUmstellungsstatus = false;

  private vertragsumstellungen$: Subscription;
  private noContractsAvaliable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private errorOnVertragsumstellungen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private vertragsumstellungen: VertragsumstellungenService) {}

  ngOnDestroy() {
    this.vertragsumstellungen$.unsubscribe();
  }

  /**
   * Filters contracts according to filter parameters
   *
   * @param filters
   */
  public filterVertragsumstellungen(filters: FilterParametersInterface): void {
    this.setFilters(filters);
    this.applyDataModifier();
  }

  /**
   * Returns current chosen sort option
   */
  public getCurrentActiveSort(): SortOptions {
    return this.currentSortAttribute;
  }

  /**
   * Returns current search terms
   */
  public getCurrentSearchTerms(): string {
    return this.currentSearchTerms.join(" ");
  }

  /**
   * Returns active filter parameters
   */
  public getFilters(): FilterParametersInterface {
    return this.filterParameters;
  }

  /**
   * Returns Array of Vertragsumstellungen as Observable, where umstellungsstatus === UmstellungsstatusEnum.OFFEN
   */
  public getOffeneVertragsumstellungen$(): BehaviorSubject<Vertragsumstellung[]> {
    return this.offeneVertragsumstellungen$;
  }

  /**
   * Returns Array of Vertragsumstellungen as Observable,
   * where umstellungsstatus === UmstellungsstatusEnum.VERSENDET || UmstellungsstatusEnum.ABGELAUFEN || UmstellungsstatusEnum.ANGENOMMEN
   */
  public getVersendeteVertragsumstellungen$(): BehaviorSubject<Vertragsumstellung[]> {
    return this.versendeteVertragsumstellungen$;
  }

  /**
   * Returns Array of Vertragsumstellungen as Observable, where umstellungsstatus === UmstellungsstatusEnum.VERWORFEN
   */
  public getVerworfeneVertragsumstellungen$(): BehaviorSubject<Vertragsumstellung[]> {
    return this.verworfeneVertragsumstellungen$;
  }

  /**
   * Returns true after the contract has been marked as "Verworfen"
   */
  public getContractDeleted(): Observable<boolean> {
    return this.contractDeleted$.asObservable();
  }

  public isErrorOnVertragsumstellungen(): BehaviorSubject<boolean> {
    return this.errorOnVertragsumstellungen$;
  }

  public noContractsAvaliable(): BehaviorSubject<boolean> {
    return this.noContractsAvaliable$;
  }

  public setErroOnVertragsumstellungen(isError: boolean) {
    this.errorOnVertragsumstellungen$.next(isError);
  }

  /**
   * Returns boolean whether Umstellungsstatus is being loaded
   */
  public isLoadingUmstellungsstatus(): boolean {
    return this.loadingUmstellungsstatus;
  }

  /**
   * Returns boolean as observable, whether Vertraege are still being loaded from server
   */
  public isLoadingVertraege(): Observable<boolean> {
    return this.loadingVertraege$;
  }

  /**
   * Returns boolean indicating whether current active sort is ascending or not
   */
  public isCurrentSortAscending(): boolean {
    return this.currentSortAscending;
  }

  /**
   * Triggers reversing of sort order.
   * Data distributed via BehaviorSubjects offeneVertragsumstellungen$, versendeteVertragsumstellungen$, verworfeneVertragsumstellungen$,
   * via splitVertragsumstellungenByStatus
   */
  public reverseSortOrder(): void {
    this.currentSortAscending = !this.currentSortAscending;
    this.currentVisibleVertragsumstellungen.reverse();
    this.splitVertragsumstellungenByStatus();
  }

  /**
   * Triggers search for searchParam.
   * Data distributed via BehaviorSubjects offeneVertragsumstellungen$, versendeteVertragsumstellungen$, verworfeneVertragsumstellungen$,
   * via applyDataModifier
   *
   * @param searchParam
   */
  public search(searchParam: string): void {
    this.currentSearchTerms = searchParam.split(" ");
    this.applyDataModifier();
  }

  /**
   * Triggers a change of Umstellungsstatus to offen in back and front end
   *
   * @param vertragsnummer
   */
  public setUmstellungsstatusToOffen(vertragsnummer: string): void {
    this.loadingUmstellungsstatus = true;
    this.vertragsumstellungen
      .setUmstellungsstatusToOffen(vertragsnummer)
      .toPromise()
      .then(() => {
        const targetContract = this.currentVisibleVertragsumstellungen.find(
          (contract) => contract.vertrag.vertragsnummer === vertragsnummer,
        );
        if (targetContract) {
          targetContract.umstellungsstatus = UmstellungsstatusEnum.OFFEN;
          this.loadingUmstellungsstatus = false;
          this.splitVertragsumstellungenByStatus();
        }
      })
      .catch(() => {
        this.loadingUmstellungsstatus = false;
      });
  }

  /**
   * Triggers a change of Umstellungsstatus to verworfen in back and front end
   *
   * @param vertragsnummer
   */
  public setUmstellungsstatusToVerworfen(vertragsnummer: string): void {
    this.vertragsumstellungen
      .setUmstellungsstatusToVerworfen(vertragsnummer)
      .toPromise()
      .then(() => {
        this.changeVertragsstatusToVerworfen(vertragsnummer);
      })
      .catch((error) => {
        if (error.status === 409 && error.error.detail === "Statusaenderung auf VERWORFEN bereits erfolgt.") {
          this.changeVertragsstatusToVerworfen(vertragsnummer);
        }
      });
  }

  /**
   * Triggers a sort of Vertragsumstellungen by attribute
   * Data distributed via BehaviorSubjects offeneVertragsumstellungen$, versendeteVertragsumstellungen$, verworfeneVertragsumstellungen$,
   * via applyDataModifier
   *
   * @param attribute
   * @param ascending default is true
   */
  public sortBy(attribute: SortOptions, ascending: boolean = true): void {
    this.currentSortAttribute = attribute;
    this.currentSortAscending = ascending;

    this.applyDataModifier();
  }

  /**
   * Triggers the call of  Vertragsumstellungen from back end
   */
  public subscribeToVertragsumstellungen() {
    this.loadingVertraege$.next(true);
    this.vertragsumstellungen$ = this.vertragsumstellungen
      .getVertragsumstellungen()
      .pipe(
        map((res, index) => {
          this.noContractsAvaliable$.next(res._embedded.elements.length === 0);
          this.allVertragsumstellungen = res._embedded.elements;
          this.currentVisibleVertragsumstellungen = this.allVertragsumstellungen;
          if (index === 0) {
            this.sortBy(this.currentSortAttribute);
            this.splitVertragsumstellungenByStatus();
          } else {
            this.applyDataModifier();
          }
          this.loadingVertraege$.next(false);
        }),
        take(1),
      )
      .subscribe(
        () => {},
        (error) => {
          this.errorOnVertragsumstellungen$.next(true);
          this.loadingVertraege$.next(false);
        },
      );
  }

  /**
   * Triggers an update of Umstellungsfaehigkeit from the back end
   *
   * @param vertragNr
   * @param bestandsvertragUmstellungsfaehigkeit
   */
  public updateUmstellungsfaehigkeit(
    vertragNr: string,
    bestandsvertragUmstellungsfaehigkeit: UmstellungsfaehigkeitEnum,
  ): Observable<Vertragsumstellungsfaehigkeit> {
    return this.vertragsumstellungen.setUmstellungsfaehigkeit(vertragNr, bestandsvertragUmstellungsfaehigkeit).pipe(
      tap((vertragsumstellungsfaehigkeit) => {
        const targetContract = this.currentVisibleVertragsumstellungen?.find(
          (contract) => contract.vertrag.vertragsnummer === vertragNr,
        );
        if (targetContract) {
          targetContract.vertrag.umstellungsfaehigkeit = vertragsumstellungsfaehigkeit.umstellungsfaehigkeit;
          this.splitVertragsumstellungenByStatus();
        }
      }),
    );
  }

  /**
   * Triggers the process of posting the offer to the customer
   *
   * @param angebotDaten
   */
  public versendeAngebot(angebotDaten: VertragsdatenFuerAngebot): Promise<boolean> {
    return this.vertragsumstellungen
      .setUmstellungsstatusToVersendet(angebotDaten)
      .toPromise()
      .then(() => {
        const targetContract = this.currentVisibleVertragsumstellungen.find(
          (contract) => contract.vertrag.vertragsnummer === angebotDaten.vertragsnummer,
        );
        if (targetContract && angebotDaten.versandweg === "EMAIL") {
          targetContract.umstellungsstatus = UmstellungsstatusEnum.WARTE_AUF_UEBERTRAGUNG;
          targetContract.versandweg = angebotDaten.versandweg;
          targetContract.versendetAm = Date.now().toString();
          this.splitVertragsumstellungenByStatus();
        } else if (targetContract && angebotDaten.versandweg === "POST") {
          targetContract.umstellungsstatus = UmstellungsstatusEnum.VERSENDET;
          targetContract.versandweg = angebotDaten.versandweg;
          targetContract.versendetAm = Date.now().toString();
          this.splitVertragsumstellungenByStatus();
        }
        return Promise.resolve(true);
      });
  }

  private applyDataModifier(): void {
    this.searchData();
    this.sortData();
    this.splitVertragsumstellungenByStatus();
  }

  private changeVertragsstatusToVerworfen(vertragsnummer: string) {
    const targetContract = this.currentVisibleVertragsumstellungen.find(
      (contract) => contract.vertrag.vertragsnummer === vertragsnummer,
    );
    if (targetContract) {
      targetContract.umstellungsstatus = UmstellungsstatusEnum.VERWORFEN;
      this.splitVertragsumstellungenByStatus();
      this.contractDeleted$.next(true);
    }
  }

  private filterData(offeneVertragsumstellungen: Vertragsumstellung[]) {
    if (this.isSearchTermNotEmpty()) {
      return offeneVertragsumstellungen;
    } else {
      return offeneVertragsumstellungen
        .filter(this.filterUmstellungsfaehigkeitAktuellerTarif.bind(this))
        .filter(this.filterHasReise.bind(this))
        .filter(this.filterHasNoReise.bind(this))
        .filter(this.filterHasVollschutz.bind(this));
    }
  }

  private filterHasNoReise(contract: Vertragsumstellung) {
    // no filter set --> return contract
    if (!this.filterParameters.hasNoReise) {
      return true;
    } else {
      return !contract.vertrag.deckungReise || contract.vertrag.deckungReise === null;
    }
  }

  private filterHasReise(contract: Vertragsumstellung) {
    // no filter set --> return contract
    if (!this.filterParameters.hasReise) {
      return true;
    } else {
      return contract.vertrag.deckungReise !== null;
    }
  }

  private filterHasVollschutz(contract: Vertragsumstellung) {
    // no filter set --> return contract
    if (!this.filterParameters.hasVollschutz) {
      return true;
    } else {
      return (
        contract.vertrag.deckungGlas === DeckungsauspraegungEnum.VOLLSCHUTZ &&
        contract.vertrag.deckungHaftpflicht === DeckungsauspraegungEnum.VOLLSCHUTZ &&
        contract.vertrag.deckungHausrat === DeckungsauspraegungEnum.VOLLSCHUTZ &&
        (contract.vertrag.deckungReise === DeckungsauspraegungEnum.VOLLSCHUTZ ||
          contract.vertrag.deckungReise === null) &&
        contract.vertrag.deckungUnfall === DeckungsauspraegungEnum.VOLLSCHUTZ
      );
    }
  }

  private filterUmstellungsfaehigkeitAktuellerTarif(contract: Vertragsumstellung): boolean {
    const filterCriteria = [false];

    if (this.noFilterUmstellungsfaehigkeitSelected()) {
      return true;
    }

    if (this.filterParameters.isUmstellungsfaehig) {
      filterCriteria.push(
        contract.vertrag.umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.AUTOMATISCH &&
          !contract.vertrag.aktuellerTarif,
      );
    }
    if (this.filterParameters.isPerErsatzantrag) {
      filterCriteria.push(
        contract.vertrag.umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.NICHT && !contract.vertrag.aktuellerTarif,
      );
    }
    if (this.filterParameters.isBedingtUmstellungsfaehig) {
      filterCriteria.push(
        contract.vertrag.umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.TEILWEISE &&
          !contract.vertrag.aktuellerTarif,
      );
    }
    if (this.filterParameters.isAktuellerTarif) {
      filterCriteria.push(contract.vertrag.aktuellerTarif === true);
    }

    return filterCriteria.includes(true);
  }

  private isSearchTermNotEmpty(): boolean {
    return this.currentSearchTerms.length > 0 && !!this.currentSearchTerms[0];
  }

  private noFilterUmstellungsfaehigkeitSelected() {
    return (
      this.filterParameters.isPerErsatzantrag === false &&
      this.filterParameters.isBedingtUmstellungsfaehig === false &&
      this.filterParameters.isUmstellungsfaehig === false &&
      this.filterParameters.isAktuellerTarif === false
    );
  }

  private parseTarifStringForSort(tarif: string): { year: string; month: string; type: string } {
    const split = tarif.replace("/", " ").split(" ");
    return {
      year: split[2],
      month: split[1],
      type: split[0],
    };
  }

  private prepareSearchHaystack(contract: Vertragsumstellung): string {
    const date = new DatePipe(contract.vertrag.ablaufdatum).transform(
      contract.vertrag.ablaufdatum,
      "dd.MM.yyyy",
      "MESZ",
      "de-DE",
    );

    return `${contract.vertrag.versicherungsnehmer.name} ${contract.vertrag.versicherungsnehmer.vorname} ${
      contract.vertrag.tarifgeneration
    } ${date?.toString()}`.toLowerCase();
  }

  private searchData() {
    if (this.isSearchTermNotEmpty()) {
      this.currentVisibleVertragsumstellungen = this.allVertragsumstellungen.filter((contract) => {
        const haystack = this.prepareSearchHaystack(contract);
        // boolean[] of whether search terms are found in haystack
        const isTermFound: boolean[] = this.currentSearchTerms.map((term) => haystack.includes(term.toLowerCase()));
        // return whether all terms found in haystack
        return isTermFound.every((included) => included === true);
      });
    } else {
      this.currentVisibleVertragsumstellungen = this.allVertragsumstellungen;
    }
  }

  private setFilters(filters: FilterParametersInterface): void {
    this.filterParameters = filters;
  }

  private sortByTarif() {
    return (a: Vertragsumstellung, b: Vertragsumstellung) => {
      const tarifA = this.parseTarifStringForSort(a.vertrag[SortOptions.TARIF]);
      const tarifB = this.parseTarifStringForSort(b.vertrag[SortOptions.TARIF]);

      if (tarifA.year !== tarifB.year) {
        return tarifA.year > tarifB.year ? 1 : -1;
      } else if (tarifA.month !== tarifB.month) {
        return tarifA.month > tarifB.month ? 1 : -1;
      } else if (tarifA.type !== tarifB.type) {
        return tarifA.type > tarifB.type ? 1 : -1;
      } else {
        return 0;
      }
    };
  }

  private sortByUmstellungsfaehigkeit() {
    function getUmstellungsfaehigkeitRank(umstellungsfaehigkeit: UmstellungsfaehigkeitEnum): number {
      if (umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.AUTOMATISCH) return 0;
      if (umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.TEILWEISE) return 1;
      if (umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.NICHT) return 2;
    }

    return (a: Vertragsumstellung, b: Vertragsumstellung) => {
      const rankA = getUmstellungsfaehigkeitRank(a.vertrag.umstellungsfaehigkeit);
      const rankB = getUmstellungsfaehigkeitRank(b.vertrag.umstellungsfaehigkeit);

      return rankA > rankB ? 1 : rankA < rankB ? -1 : 0;
    };
  }

  private sortByVertrag(attribute: SortOptions): CompareFunction {
    return (a: Vertragsumstellung, b: Vertragsumstellung) =>
      a.vertrag[attribute] > b.vertrag[attribute] ? 1 : a.vertrag[attribute] < b.vertrag[attribute] ? -1 : 0;
  }

  private sortByVersicherungsnehmer(attribute: string): CompareFunction {
    return (a: Vertragsumstellung, b: Vertragsumstellung) =>
      a.vertrag.versicherungsnehmer[attribute] > b.vertrag.versicherungsnehmer[attribute]
        ? 1
        : a.vertrag.versicherungsnehmer[attribute] < b.vertrag.versicherungsnehmer[attribute]
        ? -1
        : 0;
  }

  private sortByVertragsumstellung(attribute: string): CompareFunction {
    return (a: Vertragsumstellung, b: Vertragsumstellung) => {
      if (b[attribute] === null || a[attribute] > b[attribute]) {
        return 1;
      }
      if (a[attribute] === null || a[attribute] < b[attribute]) {
        return -1;
      }
      return 0;
    };
  }

  private sortData() {
    const versicherungsnehmerAttrs = [SortOptions.NAME, SortOptions.VORNAME];
    const vertragsumstellungAttrs = [
      SortOptions.UMSTELLUNGSSTATUS,
      SortOptions.VERSENDET_AM,
      SortOptions.ANGENOMMEN_AM,
      SortOptions.VERSANDWEG,
    ];
    const vertragsAttrs = [SortOptions.TARIF, SortOptions.ABLAUFDATUM];

    let compareFn;

    if (versicherungsnehmerAttrs.includes(this.currentSortAttribute)) {
      compareFn = this.sortByVersicherungsnehmer(this.currentSortAttribute);
    }

    if (vertragsAttrs.includes(this.currentSortAttribute)) {
      if (this.currentSortAttribute === SortOptions.TARIF) {
        compareFn = this.sortByTarif();
      } else {
        compareFn = this.sortByVertrag(this.currentSortAttribute);
      }
    }

    if (vertragsumstellungAttrs.includes(this.currentSortAttribute)) {
      compareFn = this.sortByVertragsumstellung(this.currentSortAttribute);
    }

    if (this.currentSortAttribute === SortOptions.UMSTELLUNGSFAEHIGKEIT) {
      compareFn = this.sortByUmstellungsfaehigkeit();
    }

    this.currentVisibleVertragsumstellungen.sort(compareFn);
    if (!this.currentSortAscending) {
      this.currentVisibleVertragsumstellungen.reverse();
    }
  }

  private splitVertragsumstellungenByStatus(): void {
    const offeneVertragsumstellungen = this.filterData(
      this.currentVisibleVertragsumstellungen.filter(
        (contract) => contract.umstellungsstatus === UmstellungsstatusEnum.OFFEN,
      ),
    );

    const versendeteVertragsumstellungen = this.currentVisibleVertragsumstellungen.filter(
      (contract) =>
        contract.umstellungsstatus === UmstellungsstatusEnum.VERSENDET ||
        contract.umstellungsstatus === UmstellungsstatusEnum.ABGELAUFEN ||
        contract.umstellungsstatus === UmstellungsstatusEnum.ANGENOMMEN ||
        contract.umstellungsstatus === UmstellungsstatusEnum.WARTE_AUF_UEBERTRAGUNG ||
        contract.umstellungsstatus === UmstellungsstatusEnum.WARTE_AUF_UNTERSCHRIFT,
    );

    const verworfeneVertragsumstellungen = this.currentVisibleVertragsumstellungen.filter(
      (contract) => contract.umstellungsstatus === UmstellungsstatusEnum.VERWORFEN,
    );

    this.offeneVertragsumstellungen$.next(offeneVertragsumstellungen);
    this.versendeteVertragsumstellungen$.next(versendeteVertragsumstellungen);
    this.verworfeneVertragsumstellungen$.next(verworfeneVertragsumstellungen);
  }
}

export type CompareFunction = (a: Vertragsumstellung, b: Vertragsumstellung) => -1 | 0 | 1;
