import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { VertragDetails } from "../../dashboard/models/vertragdetails.model";
import { DeckungsauspraegungEnum } from "../../dashboard/models/deckungsauspraegungEnum";
import {
  GrundsummeMax_Progression_1000,
  GrundsummeMax_Progression_225,
  GrundsummeMax_Progression_OHNE,
  GrundsummeMin,
  UnfallRenteMinMaxEnum,
} from "../../dashboard/models/grundsumme_unfallRente.enums";
import { ProgressionEnum } from "../../dashboard/models/progression.enum";
import { GefahrengruppenEnum } from "../../dashboard/models/gefahrengruppen.enum";
import { Gliedertaxe } from "../../dashboard/models/aenderbareVertragsdatenUnfall";
import { BeitragErmittelnService } from "../../shared/services/beitrag-ermitteln.service";
import { FormcontrolsEnum } from "../../dashboard/models/formcontrols.enum";
import { FormsService } from "../../shared/services/forms.service";

@Injectable({
  providedIn: "root",
})
export class AngebotBearbeitenService {
  vertragDetails: VertragDetails;
  angebotBearbeitenForm: UntypedFormGroup;
  unfallFormArray: UntypedFormArray;

  constructor(
    private beitragErmittelnService: BeitragErmittelnService,
    private formsService: FormsService,
  ) {}

  public setDropDownValidators(
    yesNoFormControlValue: string,
    dropDownFormControlName: string,
    bearbeitenForm: UntypedFormGroup,
  ) {
    if (yesNoFormControlValue === "yes") {
      bearbeitenForm.get(dropDownFormControlName).setValidators([Validators.required]);
    } else {
      bearbeitenForm.get(dropDownFormControlName).setValidators([]);
      bearbeitenForm.get(dropDownFormControlName).setValue(null);
    }
    bearbeitenForm.get(dropDownFormControlName).updateValueAndValidity();
  }

  public setInitialUnfallRenteAndUnfallRentenvarianteValidator(
    vertragDetails: VertragDetails,
    index: number,
  ): Validators[] {
    const unfallrenteValidators = [];
    const unfallRenteBestandsvertrag = vertragDetails.vertrag.aenderbareVertragsdatenUnfall[index]?.unfallRente;
    if (unfallRenteBestandsvertrag !== null && unfallRenteBestandsvertrag !== 0) {
      const minUnfallRente = this.formsService.getUnfallRente(unfallRenteBestandsvertrag);
      unfallrenteValidators.push(Validators.required);
      unfallrenteValidators.push(Validators.min(minUnfallRente));
      unfallrenteValidators.push(Validators.max(UnfallRenteMinMaxEnum.MAX));
    }
    return unfallrenteValidators;
  }

  /**
   * Function to set and remove Unfallrente Validators if initial value is null
   */
  public setUnfallRenteValidators(userInputUnfallRente: number, selectedIndex: number) {
    const vertragsValue = this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall[selectedIndex]?.unfallRente;
    if ((vertragsValue === null || vertragsValue === 0) && userInputUnfallRente > 0) {
      this.unfallFormArray
        .at(selectedIndex)
        .get(FormcontrolsEnum.UNFALLRENTE)
        .setValidators([Validators.min(UnfallRenteMinMaxEnum.MIN), Validators.max(UnfallRenteMinMaxEnum.MAX)]);
      this.unfallFormArray.at(selectedIndex).get(FormcontrolsEnum.UNFALLRENTE).updateValueAndValidity();
    } else if (
      (vertragsValue === null || vertragsValue === 0) &&
      (userInputUnfallRente === null || userInputUnfallRente === 0)
    ) {
      this.unfallFormArray.at(selectedIndex).get(FormcontrolsEnum.UNFALLRENTE).clearValidators();
      this.unfallFormArray.at(selectedIndex).get(FormcontrolsEnum.UNFALLRENTE).updateValueAndValidity();
    }
  }

  public setInvaliditaetsgrundsummeValidator(
    formGroup: UntypedFormGroup,
    vertragDetails: VertragDetails,
    index: number,
  ): Validators[] {
    try {
      const [progression, gefahrengruppe] = this.beitragErmittelnService.getProgressionAndGfgForPerson(
        vertragDetails.vertrag.aenderbareVertragsdatenUnfall[index].versichertePerson,
      );
      const minGrundsumme = this.formsService.getInvaliditaetsGrundsumme(
        vertragDetails.vertrag.aenderbareVertragsdatenUnfall[index].invaliditaetsGrundsumme,
        progression,
      );
      return [
        Validators.min(minGrundsumme),
        Validators.max(this.getGrundsummeMax(progression, gefahrengruppe)),
        Validators.required,
      ];
    } catch (error) {
      // for now: keep going without max validator
      //TODO: Error Handling if no progression available ???
      return [Validators.min(formGroup.get(FormcontrolsEnum.INVALIDITAETSGRUNDSUUME).value), Validators.required];
    }
  }

  /**
   * Gives back maximum possible value for Invaliditätsgrundsumme according to the progression
   */
  public getGrundsummeMax(progression: ProgressionEnum, gfg: GefahrengruppenEnum) {
    switch (progression) {
      case ProgressionEnum.PROGRESSION_225:
        return gfg === GefahrengruppenEnum.K
          ? GrundsummeMax_Progression_225.GFG_K
          : GrundsummeMax_Progression_225.GFG_A_B;
      case ProgressionEnum.PROGRESSION_1000:
        return gfg === GefahrengruppenEnum.A || gfg === GefahrengruppenEnum.B || gfg === GefahrengruppenEnum.AB
          ? GrundsummeMax_Progression_1000.GFG_A_B
          : GrundsummeMax_Progression_1000.GFG_K;
      case ProgressionEnum.PROGRESSION_OHNE:
        return GrundsummeMax_Progression_OHNE.GFG_S;
      default:
        console.log(`${progression} unbekannt`);
        break;
    }
  }

  public getGrundsummeMin(progression: ProgressionEnum) {
    switch (progression) {
      case ProgressionEnum.PROGRESSION_225:
        return GrundsummeMin.PROGRESSION_225;
      case ProgressionEnum.PROGRESSION_1000:
        return GrundsummeMin.PROGRESSION_1000;
      case ProgressionEnum.PROGRESSION_OHNE:
        return GrundsummeMin.PROGRESSION_OHNE;
      default:
        console.log(`${progression} nicht bekannt`);
        break;
    }
  }

  /**
   * Sets or removes the validator for unfallRentenvariante Form, when no validator has been set on initialisation.
   * The field becomes required, when the user enters a value into the unfallRente Form. In this case a validator is added.
   * The validator is removed, when the user deletes the value (ONLY happens, when NO initial validator was set)
   */
  public setUnfallRentenvarianteValidator(unfallFormArray: UntypedFormArray, selectedUnfallIndex: number) {
    const unfallRentenvarianteForm = unfallFormArray.at(selectedUnfallIndex).get(FormcontrolsEnum.UNFALLRENTENVARIANTE);
    const unfallRenteForm = unfallFormArray.at(selectedUnfallIndex).get(FormcontrolsEnum.UNFALLRENTE);

    if (unfallRentenvarianteForm.validator === null && unfallRenteForm.value !== "") {
      unfallRentenvarianteForm.setValidators([Validators.required]);
      unfallRentenvarianteForm.updateValueAndValidity();
    } else if (unfallRenteForm.validator === null) {
      unfallRentenvarianteForm.setValidators([]);
      unfallRentenvarianteForm.updateValueAndValidity();
    }
  }

  /**
   * Calls an associated function for every form-control in the angebot-bearbeiten component, which enables or
   * disables the field based on its respective rules
   */
  public setDisabledState(
    angebotBearbeitenForm: UntypedFormGroup,
    unfallFormArray: UntypedFormArray,
    vertragDetails: VertragDetails,
  ) {
    this.vertragDetails = vertragDetails;
    this.angebotBearbeitenForm = angebotBearbeitenForm;
    this.unfallFormArray = unfallFormArray;
    Object.entries(angebotBearbeitenForm.controls).forEach((key) => {
      switch (key[0]) {
        case FormcontrolsEnum.MIT_NEUWERTSCHAEDIGUNG:
          this.checkMitNeuwertschaedigung(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.HAUSRAT_FUER_ZWEITWOHNUNG:
          this.checkHausratInZweitwohnung(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.KONTOSCHUTZBRIEF:
          this.checkKontoschutzbrief(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.HAUSRAT_ANGEHOERIGEN:
          this.checkAngehoerigen(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.HAUSRATOPTIMAL_XXL:
          this.checkHausratOptimalXXL(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.FAHRRAD_PLUS:
          this.checkFahrradPlus(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.FREIZEIT_PLUS:
          this.checkFreizeitPlus(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.REISEGEPAECK:
          this.checkReisegepaeck(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.REISERUECKTRITT:
          this.checkReiseruecktritt(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.REISEASSISTANCELEISTUNGEN:
          this.checkReiseassistanceleistungen(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.GRUNDFLAECHE:
          this.checkGrundflaeche(key[1] as UntypedFormControl);
          break;
        case FormcontrolsEnum.AENDERBAREVERTRAGSDATEN_UNFALL:
          for (let i = 0; i < this.unfallFormArray.length; i++) {
            this.checkUnfallrentenvariante(this.unfallFormArray.at(i).get(FormcontrolsEnum.UNFALLRENTENVARIANTE), i);
            this.checkGliedertaxe(this.unfallFormArray.at(i).get(FormcontrolsEnum.GLIEDERTAXE), i);
          }
          break;
        default:
          break;
      }
    });
  }

  private checkMitNeuwertschaedigung(field: UntypedFormControl) {
    if (
      this.vertragDetails.vertrag.deckungHaftpflicht !== DeckungsauspraegungEnum.ANWARTSCHAFT &&
      this.vertragDetails.vertrag.aenderbareVertragsdatenHaftpflicht?.neuwertentschaedigungEigeneSachen === false
    ) {
      field.enable();
    } else {
      field.disable();
    }
  }

  private checkHausratOptimalXXL(field: UntypedFormControl) {
    if (
      this.vertragDetails.vertrag.deckungHausrat !== DeckungsauspraegungEnum.ANWARTSCHAFT &&
      this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.weitereElementargefahren === true &&
      this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.hausratOptimalXxl === false
    ) {
      field.enable();
    } else {
      field.disable();
    }
  }

  private checkFahrradPlus(field: AbstractControl) {
    if (
      this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.fahrradPlus === false &&
      this.vertragDetails.vertrag.deckungHausrat !== DeckungsauspraegungEnum.ANWARTSCHAFT &&
      this.isHausratOptimalXxlDisabledOrEnabledAndValueSetToNo()
    ) {
      field.enable();
    } else {
      if (!this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.fahrradPlus) {
        field.patchValue("no");
      }
      field.disable();
    }
  }

  private checkFreizeitPlus(field: AbstractControl) {
    if (
      this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.freizeitPlus === false &&
      this.vertragDetails.vertrag.deckungHausrat !== DeckungsauspraegungEnum.ANWARTSCHAFT &&
      this.isHausratOptimalXxlDisabledOrEnabledAndValueSetToNo()
    ) {
      field.enable();
    } else {
      if (!this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.freizeitPlus) {
        field.patchValue("no");
      }
      field.disable();
    }
  }

  private checkKontoschutzbrief(field: UntypedFormControl) {
    if (
      this.vertragDetails.vertrag.deckungHausrat !== DeckungsauspraegungEnum.ANWARTSCHAFT &&
      this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.kontoFinanzschutzbrief === false
    ) {
      field.enable();
    } else {
      field.disable();
    }
  }

  private checkHausratInZweitwohnung(field: UntypedFormControl) {
    if (
      this.vertragDetails.vertrag.deckungHausrat !== DeckungsauspraegungEnum.ANWARTSCHAFT &&
      this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.hausratFuerZweitwohnungStaendigBewohntesGebaeude ===
        false
    ) {
      field.enable();
    } else {
      if (
        !this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.hausratFuerZweitwohnungStaendigBewohntesGebaeude
      ) {
        field.patchValue("no");
      }
      field.disable();
    }
  }

  private checkAngehoerigen(field: UntypedFormControl) {
    if (
      this.vertragDetails.vertrag.deckungHausrat !== DeckungsauspraegungEnum.ANWARTSCHAFT &&
      this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.hausratAngehoerigeSeniorenheim === false
    ) {
      field.enable();
    } else {
      if (!this.vertragDetails.vertrag.aenderbareVertragsdatenHausrat?.hausratAngehoerigeSeniorenheim) {
        field.patchValue("no");
      }
      field.disable();
    }
  }

  private checkReisegepaeck(field: UntypedFormControl) {
    if (
      (this.vertragDetails.vertrag.aenderbareVertragsdatenReise?.reisegepaeck === false ||
        this.vertragDetails.vertrag.aenderbareVertragsdatenReise === null) &&
      this.isHausratOptimalXxlDisabledOrEnabledAndValueSetToNo()
    ) {
      field.enable();
    } else {
      if (!this.vertragDetails.vertrag.aenderbareVertragsdatenReise?.reisegepaeck) {
        field.patchValue("no");
      }
      field.disable();
    }
  }

  private checkReiseruecktritt(field: UntypedFormControl) {
    if (
      this.vertragDetails.vertrag.aenderbareVertragsdatenReise?.reiseruecktritt === false ||
      this.vertragDetails.vertrag.aenderbareVertragsdatenReise === null
    ) {
      field.enable();
    } else {
      if (!this.vertragDetails.vertrag.aenderbareVertragsdatenReise?.reiseruecktritt) {
        field.patchValue("no");
      }
      field.disable();
    }
  }

  private checkReiseassistanceleistungen(field: UntypedFormControl) {
    if (
      (this.vertragDetails.vertrag.aenderbareVertragsdatenReise?.reiseAssistanceleistungenInklAuslandsreisekranken ===
        false ||
        this.vertragDetails.vertrag.aenderbareVertragsdatenReise === null) &&
      this.isHausratOptimalXxlOrReisegepaeckOrReiseruecktrittSetToYes()
    ) {
      field.enable();
    } else {
      if (
        !this.vertragDetails.vertrag.aenderbareVertragsdatenReise?.reiseAssistanceleistungenInklAuslandsreisekranken
      ) {
        field.patchValue("no");
      }
      field.disable();
    }
  }

  private checkGrundflaeche(field: UntypedFormControl) {
    if (this.vertragDetails.vertrag.deckungGlas !== DeckungsauspraegungEnum.ANWARTSCHAFT) {
      field.enable();
    } else {
      field.disable();
    }
  }

  private checkUnfallrentenvariante(field: AbstractControl, index: number) {
    const unfallRenteForm = this.unfallFormArray.at(index).get(FormcontrolsEnum.UNFALLRENTE);
    if (
      unfallRenteForm.enabled === true &&
      unfallRenteForm.value !== 0 &&
      (this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall[index]?.unfallRente === null ||
        this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall[index]?.unfallRente === 0)
    ) {
      field.enable();
    } else {
      field.disable();
    }
  }

  private checkGliedertaxe(field: AbstractControl, index: number) {
    if (
      this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall[index]?.gliedertaxe === null ||
      this.vertragDetails.vertrag.aenderbareVertragsdatenUnfall[index]?.gliedertaxe === Gliedertaxe.VORHANDEN
    ) {
      field.enable();
    } else {
      field.disable();
    }
  }

  private isHausratOptimalXxlDisabledOrEnabledAndValueSetToNo(): boolean {
    if (this.angebotBearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).disabled === true) {
      return true;
    } else {
      return (
        this.angebotBearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).enabled &&
        this.angebotBearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).value === "no"
      );
    }
  }

  private isHausratOptimalXxlOrReisegepaeckOrReiseruecktrittSetToYes() {
    return (
      this.angebotBearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).value === "yes" ||
      this.angebotBearbeitenForm.get(FormcontrolsEnum.REISEGEPAECK).value === "yes" ||
      this.angebotBearbeitenForm.get(FormcontrolsEnum.REISERUECKTRITT).value === "yes"
    );
  }
}
