import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { User } from "../dashboard/models/user";
import { KeycloakService } from "../shared/services/keycloak.service";
import { VertragsumstellungenService } from "../shared/services/vertragsumstellungen.service";
import { EnvironmentService } from "../shared/services/environment.service";
import { Observable } from "rxjs";
import { MandantEnum } from "../shared/models/mandant.enum";
import { map } from "rxjs/operators";
import { AssistentService } from "../shared/services/assistent.service";
import { VertraegeService } from "../dashboard/services/vertraege.service";
import { RouteWatchService } from "../shared/services/route-watch.service";
import { Router } from "@angular/router";
import packageJson from "../../../package.json";

@Component({
  selector: "app-more-menu",
  templateUrl: "./more-menu.component.html",
  styleUrls: ["./more-menu.component.scss"],
})
export class MoreMenuComponent implements OnInit {
  frontendVersion: string = packageJson.version;
  @ViewChild("moreMenu") public moreMenu: ElementRef;
  datenschutzURL = "https://www.dvag.de/dvag/datenschutz.html";
  user: User;
  logoType$: Observable<"default" | "allfinanz">;
  moreMenuOpen = false;
  isNutzungsbedingungenOpen = false;
  showHauptbenutzerList = false;

  constructor(
    public keycloakService: KeycloakService,
    public vertragsumstellungenService: VertragsumstellungenService,
    public environment: EnvironmentService,
    public assistentService: AssistentService,
    private vertraege: VertraegeService,
    private routeWatchService: RouteWatchService,
    private router: Router,
  ) {}

  @HostListener("document:click", ["$event"])
  clickout(event: MouseEvent) {
    // Here we check if a click needs to make the More menu disappear.
    // If More menu is open ...
    if (this.moreMenu && !this.moreMenu.nativeElement.contains(event.target) && this.moreMenuOpen) {
      // click was outside More menu -> toggle it away
      this.moreMenuOpen = false;
    }
  }

  ngOnInit(): void {
    this.getUserData();
    this.logoType$ = this.keycloakService.getMandant().pipe(
      map((mandant) => {
        if (mandant === MandantEnum.ALLFINANZ || mandant === MandantEnum.ALLFINANZ_AG) {
          return "allfinanz";
        } else {
          return "default";
        }
      }),
    );
  }

  toggleMoreMenu(event: Event) {
    this.moreMenuOpen = !this.moreMenuOpen;
    if (event && event.stopPropagation) {
      // fixes Error in tests
      event.stopPropagation();
    }
  }

  async getUserData() {
    this.user = await this.keycloakService.getUserData();
  }

  logout() {
    this.keycloakService.logout();
  }

  // Opens Nutzungsbedingungen Compnent in Modal
  showNutzungsbedingung() {
    this.moreMenuOpen = !this.moreMenuOpen;
    this.isNutzungsbedingungenOpen = true;
  }

  // Gets emitted value from modal component and sets IsNutzungsbedingungenOpen variable
  getNutzungsbedingungenVisibilityStatus(status: boolean) {
    this.isNutzungsbedingungenOpen = status;
  }

  showDatenschutz() {
    window.open(this.datenschutzURL, "_blank");
  }

  // Function for toggling Umstellungsfaehigkeitsaktualisierung for testing purposes
  sendUmstellungsfaehigkeitsAktualisierungAuswahl(value: "yes" | "no" | "unset") {
    if (value === "no") {
      this.vertragsumstellungenService.setisUmstellungsFaehigkeitAktiv(false);
    } else {
      this.vertragsumstellungenService.setisUmstellungsFaehigkeitAktiv(true);
    }
  }

  selectHauptbenutzer(id: string) {
    this.vertraege.setErroOnVertragsumstellungen(false);
    this.moreMenuOpen = false;
    this.showHauptbenutzerList = false;
    this.assistentService.setSelectedHauptbenutzerId(id);
    if (this.routeWatchService.getCurrentUrl() !== "/") {
      this.router.navigate(["/"]);
      this.vertraege.subscribeToVertragsumstellungen();
    } else {
      this.vertraege.subscribeToVertragsumstellungen();
    }
  }

  loadEigeneVertraege() {
    this.showHauptbenutzerList = false;
    this.vertraege.setErroOnVertragsumstellungen(false);
    this.moreMenuOpen = false;
    this.assistentService.setSelectedHauptbenutzerId("");
    if (this.routeWatchService.getCurrentUrl() !== "/") {
      this.router.navigate(["/"]);
      this.vertraege.subscribeToVertragsumstellungen();
    } else {
      this.vertraege.subscribeToVertragsumstellungen();
    }
  }

  openHauptbenutzerList() {
    this.showHauptbenutzerList = !this.showHauptbenutzerList;
  }
}
