export enum FormcontrolsEnum {
  EMAIL = "email",
  TELEFON = "telefon",
  FLEX_N = "flexN",
  VERSANDWEG = "versandweg",
  VERTRAUENSMITARBEITERIN = "vertrauensmitarbeiterIn",
  ANSCHREIBEN = "anschreiben",
  MIT_NEUWERTSCHAEDIGUNG = "neuwertentschaedigungEigeneSachen",
  VERSICHERUNGSSUMME_HAUSRAT_FUER_ZWEITWOHNUNG = "versicherungssummeHausratFuerZweitwohnungInStaendigBewohntemGebaeude",
  HAUSRAT_FUER_ZWEITWOHNUNG = "hausratFuerZweitwohnungStaendigBewohntesGebaeude",
  KONTOSCHUTZBRIEF = "kontoFinanzschutzbrief",
  HAUSRAT_ANGEHOERIGEN = "hausratAngehoerigeSeniorenheim",
  VERSICHERUNGSSUMME_HAUSRAT_ANGEHOERIGE = "versicherungssummeHausratAngehoerigeSeniorenheim",
  HAUSRATOPTIMAL_XXL = "hausratOptimalXxl",
  FAHRRAD_PLUS = "fahrradPlus",
  FREIZEIT_PLUS = "freizeitPlus",
  VERSICHERUNGSSUMME_FREIZEIT_PLUS = "versicherungssummeFreizeitPlus",
  REISEGEPAECK = "reisegepaeck",
  VERSICHERUNGSSUMME_REISEGEPAECK = "versicherungssummeReisegepaeck",
  REISERUECKTRITT = "reiseruecktritt",
  VERSICHERUNGSSUMME_REISERUECKTRITT = "versicherungssummeReiseruecktritt",
  REISEASSISTANCELEISTUNGEN = "reiseAssistanceleistungenInklAuslandsreisekranken",
  GRUNDFLAECHE = "grundflaeche",
  GLASSCHEIBEN = "glasscheiben",
  AENDERBAREVERTRAGSDATEN_UNFALL = "aenderbareVertragsdatenUnfall",
  INVALIDITAETSGRUNDSUUME = "invaliditaetsGrundsumme",
  UNFALLRENTENVARIANTE = "unfallRentenvariante",
  UNFALLRENTE = "unfallRente",
  GLIEDERTAXE = "gliedertaxe",
}
