import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "./environment.service";
import { Observable } from "rxjs";
import { KommunikationsverbindungenModel } from "../models/kommunikationsverbindungen.model";
import { EmptyResponse } from "./vertragsumstellungen.service";

@Injectable({
  providedIn: "root",
})
export class KommunikationsverbindungenService {
  public backendBasePath: string;
  constructor(
    protected httpClient: HttpClient,
    private environment: EnvironmentService,
  ) {
    this.backendBasePath = this.environment.getBackendUrl();
  }

  addKommunikationsverbindungen(
    kundennummer: string,
    kommunikationsverbindungen: KommunikationsverbindungenModel,
  ): Observable<EmptyResponse> {
    return this.httpClient.post(
      `${this.backendBasePath}/vertragsumstellungen/${kundennummer}/kommunikationsverbindungen`,
      { ...kommunikationsverbindungen },
    );
  }
}
