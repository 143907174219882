import { Injectable } from "@angular/core";
import { VertragDetails } from "../../dashboard/models/vertragdetails.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { EnvironmentService } from "src/app/shared/services/environment.service";
import { mergeMap, tap } from "rxjs/operators";
import { KeycloakService } from "./keycloak.service";
import { KontostandFlexNModel } from "../models/kontostandFlexN.model";
import { HttpHelperService } from "./http-helper.service";
import { EinwilligungserklaerungRequestModel } from "../models/einwilligungserklaerungRequest.model";
import { KundendatenModel } from "../models/kundendaten.model";
import { EmptyResponse } from "./vertragsumstellungen.service";
import { AssistentService } from "./assistent.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class VertragdetailsService {
  public backendBasePath: string;
  public vertragDetails: VertragDetails;
  private readonly MIME_TYPE_JSON = "application/json";
  private vertragsnummer: string;

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService,
    private keycloak: KeycloakService,
    private httpHelperService: HttpHelperService,
    private assistentService: AssistentService,
    private userService: UserService,
  ) {
    this.backendBasePath = this.environment.getBackendUrl();
  }

  public getDetails(vertragsnummer: string): Observable<VertragDetails> {
    return this.keycloak.getMandant().pipe(
      mergeMap((mandant) => {
        return this.httpClient
          .get<VertragDetails>(`${this.backendBasePath}/vertragsumstellungen/${vertragsnummer}?mandant=${mandant}`)
          .pipe(
            tap((result) => {
              this.vertragDetails = result;
            }),
          );
      }),
    );
  }

  public getKontostandNachlasskonto(): Observable<KontostandFlexNModel[]> {
    const hauptnutzerNummer = this.assistentService.getIsEigenerBestand()
      ? of(this.userService.getVbNummer())
      : this.assistentService.getSelectedHauptbenutzerId$();
    return hauptnutzerNummer.pipe(
      mergeMap((vbNummer: string) => {
        if (vbNummer === null || vbNummer === undefined) {
          throwError(
            new Error("Required parameter vbNummer was null or undefined when calling getKontostandNachlasskonto."),
          );
        }

        let queryParameters = this.httpHelperService.newHttpParams();
        if (vbNummer) {
          queryParameters = this.httpHelperService.addToHttpParams(queryParameters, vbNummer, "vbNummer");
        }

        const headers = this.httpHelperService.newHttpHeader("Accept", this.MIME_TYPE_JSON);

        const responseType = "json";

        return this.httpClient.get<KontostandFlexNModel[]>(`${this.backendBasePath}/nachlasskonten`, {
          params: queryParameters,
          responseType,
          headers,
        });
      }),
    );
  }

  public getVertragsdetails(): VertragDetails {
    return this.vertragDetails;
  }

  public getVertragsnummer(): string {
    return this.vertragsnummer;
  }

  public postEinwilligungserklaerung(kundenDaten: KundendatenModel): Promise<EmptyResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    const body = this.buildEinwilligungserklaerungRequestModel(kundenDaten);
    return this.httpClient
      .post<EmptyResponse>(`${this.environment.getBackendUrl()}/einwilligungserklaerungen`, body, httpOptions)
      .toPromise();
  }

  public setVertragsnummer(vertragsnummer: string) {
    this.vertragsnummer = vertragsnummer;
  }

  public reset() {
    this.vertragsnummer = "";
    this.vertragDetails = null;
  }

  private buildEinwilligungserklaerungRequestModel(kundenDaten): EinwilligungserklaerungRequestModel {
    const vermoegensberater = this.userService.getUserOrContextUserData();
    return {
      vermoegensberater,
      kunde: kundenDaten,
    };
  }
}
