import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validateMobilePhoneNumberLength(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value?.replaceAll(" ", "");
    const tooShort = value?.length < 8;
    const tooLong = value?.length > 17;
    return tooLong || tooShort ? { validateMobilePhoneNumberLength: control.value } : null;
  };
}

export function validateMobilePhoneNumberPattern(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value?.replaceAll(" ", "");
    // regexps taken from ADS
    const fuehrendeNullLokal = /^0[1-9][0-9]*$/;
    const internationalDoppelnull = /^00[1-9][0-9]*$/;
    const internationalPlus = /^\+[1-9][0-9]*$/;
    const patterns = [fuehrendeNullLokal, internationalDoppelnull, internationalPlus];

    const isPhonePatternEmptyAfterReplace = value === "";
    const isPhonePatternWrong = patterns.map((re) => re.test(value)).every((pattern) => pattern === false);
    return isPhonePatternEmptyAfterReplace || isPhonePatternWrong
      ? { validateMobilePhoneNumberPattern: control.value }
      : null;
  };
}
