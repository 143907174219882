import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { VertragDetails } from "../../dashboard/models/vertragdetails.model";
import { Beitragsberechnung } from "../../dashboard/models/beitragsberechnung";
import { FormcontrolsEnum } from "../../dashboard/models/formcontrols.enum";
import { AenderbareVertragsdatenHausrat } from "../../dashboard/models/aenderbareVertragsdatenHausrat";
import { AenderbareVertragsdatenGlas } from "../../dashboard/models/aenderbareVertragsdatenGlas";
import { AenderbareVertragsdatenHaftpflicht } from "../../dashboard/models/aenderbareVertragsdatenHaftpflicht";
import { AenderbareVertragsdatenReise } from "../../dashboard/models/aenderbareVertragsdatenReise";
import {
  AenderbareVertragsdatenUnfall,
  Gliedertaxe,
  Rentenvariante,
} from "../../dashboard/models/aenderbareVertragsdatenUnfall";
import { AngebotBearbeitenService } from "../../angebot-bearbeiten/services/angebot-bearbeiten.service";
import { BeitragErmittelnService } from "./beitrag-ermitteln.service";
import { ProgressionEnum } from "../../dashboard/models/progression.enum";
import { UnfallRenteMinMaxEnum } from "../../dashboard/models/grundsumme_unfallRente.enums";

@Injectable({
  providedIn: "root",
})
export class VertragsdatenMappingService {
  constructor(
    private angebotBearbeitenService: AngebotBearbeitenService,
    private beitragErmittelnService: BeitragErmittelnService,
  ) {}

  public mapToBeitragsberechnung(
    bearbeitenForm: UntypedFormGroup,
    detailsForm: UntypedFormGroup,
    vertragDetails: VertragDetails,
    minderbeitragBeseitig: boolean = false,
  ): Beitragsberechnung {
    const dataModelForTarifrechener: Beitragsberechnung = {
      minderbeitragBeseitigen: minderbeitragBeseitig,
    };

    if (
      !Number.isNaN(detailsForm.get(FormcontrolsEnum.FLEX_N).value) &&
      detailsForm.get(FormcontrolsEnum.FLEX_N).value !== null
    ) {
      dataModelForTarifrechener.flexn = detailsForm.get(FormcontrolsEnum.FLEX_N).value;
    }

    const aenderbarevertragsdatenHausrat = this.setAenderbareVertragsdatenHausrat(vertragDetails, bearbeitenForm);
    if (aenderbarevertragsdatenHausrat !== null) {
      dataModelForTarifrechener.aenderbareVertragsdatenHausrat = aenderbarevertragsdatenHausrat;
    }

    const aenderbareVertragsdatenGlas = this.setAenderbareVertragsdatenGlas(vertragDetails, bearbeitenForm);
    if (aenderbareVertragsdatenGlas !== null) {
      dataModelForTarifrechener.aenderbareVertragsdatenGlas = aenderbareVertragsdatenGlas;
    }

    const aenderbareVertragsdatenHaftpflicht = this.setAenderbareVertragsdatenHaftpflicht(
      vertragDetails,
      bearbeitenForm,
    );

    if (aenderbareVertragsdatenHaftpflicht !== null) {
      dataModelForTarifrechener.aenderbareVertragsdatenHaftpflicht = aenderbareVertragsdatenHaftpflicht;
    }

    const aenderbareVertragsdatenReise = this.setAenderbareVertragsdatenReise(vertragDetails, bearbeitenForm);
    if (aenderbareVertragsdatenReise !== null) {
      dataModelForTarifrechener.aenderbareVertragsdatenReise = aenderbareVertragsdatenReise;
    }

    const aenderbareVertragsdatenUnfall = this.setAenderbareVertragsdatenUnfall(vertragDetails, bearbeitenForm);
    if (aenderbareVertragsdatenUnfall.length > 0) {
      dataModelForTarifrechener.aenderbareVertragsdatenUnfall = aenderbareVertragsdatenUnfall;
    }
    return dataModelForTarifrechener;
  }

  /**
   * Function to filter out object attributes with null values
   */
  private filterOutChangedValues(object: any): any {
    const objectAsArray = Object.entries(object);
    const filterOutNullValues = objectAsArray.filter(([key, value]) => value !== null);
    const filteredObject = {};
    if (filterOutNullValues.length > 0) {
      filterOutNullValues.forEach(([key, value]) => {
        filteredObject[key] = value;
      });
      return filteredObject;
    }
    return null;
  }

  /**
   * Sparte Hausrat wird vorbereitet für Den Tarifrechner. Falls die Werte in Form anders als im Bestandsvertrag sind,
   * werden die Formwerte an den Tarifrechner geschickt.
   */
  private setAenderbareVertragsdatenHausrat(
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ): AenderbareVertragsdatenHausrat {
    const aenderbareVertragsdatenHausrat: AenderbareVertragsdatenHausrat = {
      versicherungssummeHausratFuerZweitwohnungInStaendigBewohntemGebaeude: this.setBeitragsmodelValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_FUER_ZWEITWOHNUNG,
        vertragDetails,
        bearbeitenForm,
      ),
      hausratFuerZweitwohnungStaendigBewohntesGebaeude: this.setBeitragsmodelYesNoValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        FormcontrolsEnum.HAUSRAT_FUER_ZWEITWOHNUNG,
        vertragDetails,
        bearbeitenForm,
      ),
      kontoFinanzschutzbrief: this.setBeitragsmodelYesNoValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        FormcontrolsEnum.KONTOSCHUTZBRIEF,
        vertragDetails,
        bearbeitenForm,
      ),
      hausratAngehoerigeSeniorenheim: this.setBeitragsmodelYesNoValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        FormcontrolsEnum.HAUSRAT_ANGEHOERIGEN,
        vertragDetails,
        bearbeitenForm,
      ),
      versicherungssummeHausratAngehoerigeSeniorenheim: this.setBeitragsmodelValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        FormcontrolsEnum.VERSICHERUNGSSUMME_HAUSRAT_ANGEHOERIGE,
        vertragDetails,
        bearbeitenForm,
      ),
      hausratOptimalXxl: this.setBeitragsmodelYesNoValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        FormcontrolsEnum.HAUSRATOPTIMAL_XXL,
        vertragDetails,
        bearbeitenForm,
      ),
      fahrradPlus: this.setValuesThatDependOnHausratOptimalXXL(
        FormcontrolsEnum.FAHRRAD_PLUS,
        bearbeitenForm,
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        vertragDetails,
      ),
      freizeitPlus: this.setValuesThatDependOnHausratOptimalXXL(
        FormcontrolsEnum.FREIZEIT_PLUS,
        bearbeitenForm,
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        vertragDetails,
      ),
      versicherungssummeFreizeitPlus: this.setBeitragsmodelValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_HAUSRAT,
        FormcontrolsEnum.VERSICHERUNGSSUMME_FREIZEIT_PLUS,
        vertragDetails,
        bearbeitenForm,
      ),
    };

    if (this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.HAUSRAT_FUER_ZWEITWOHNUNG).value) !== true) {
      aenderbareVertragsdatenHausrat.versicherungssummeHausratFuerZweitwohnungInStaendigBewohntemGebaeude = null;
    }

    if (this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.HAUSRAT_ANGEHOERIGEN).value) !== true) {
      aenderbareVertragsdatenHausrat.versicherungssummeHausratAngehoerigeSeniorenheim = null;
    }

    if (
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.FREIZEIT_PLUS).value) !== true ||
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).value) === true
    ) {
      aenderbareVertragsdatenHausrat.versicherungssummeFreizeitPlus = null;
    }

    return this.filterOutChangedValues(aenderbareVertragsdatenHausrat);
  }

  /**
   * Sparte Glas wird vorbereitet für Den Tarifrechner. Falls die Werte in Form anders als im Bestandsvertrag sind,
   * werden die Formwerte an den Tarifrechner geschickt.
   */
  private setAenderbareVertragsdatenGlas(
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ): AenderbareVertragsdatenGlas {
    const aenderbareVertragsdatenGlas: AenderbareVertragsdatenGlas = {};
    const grundflaeche = this.setBeitragsmodelValue(
      SpartenEnum.AENDERBAREVERTRAGSDATEN_GLAS,
      FormcontrolsEnum.GRUNDFLAECHE,
      vertragDetails,
      bearbeitenForm,
    );
    if (grundflaeche !== null) {
      aenderbareVertragsdatenGlas.grundflaeche = grundflaeche;
      return aenderbareVertragsdatenGlas;
    }
    return null;
  }

  /**
   * Sparte Haftpflicht wird vorbereitet für Den Tarifrechner. Falls die Werte in Form anders als im Bestandsvertrag sind,
   * werden die Formwerte an den Tarifrechner geschickt.
   */
  private setAenderbareVertragsdatenHaftpflicht(
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ): AenderbareVertragsdatenHaftpflicht {
    const aenderbareVertragsdatenHaftpflicht: AenderbareVertragsdatenHaftpflicht = {};
    const neuwertentschaedigungEigeneSachen = this.setBeitragsmodelYesNoValue(
      SpartenEnum.AENDERBAREVERTRAGSDATEN_HAFTPFLICHT,
      FormcontrolsEnum.MIT_NEUWERTSCHAEDIGUNG,
      vertragDetails,
      bearbeitenForm,
    );

    if (neuwertentschaedigungEigeneSachen !== null) {
      aenderbareVertragsdatenHaftpflicht.neuwertentschaedigungEigeneSachen = neuwertentschaedigungEigeneSachen;
      return aenderbareVertragsdatenHaftpflicht;
    }
    return null;
  }

  /**
   * Sparte Reise wird vorbereitet für Den Tarifrechner. Falls die Werte in Form anders als im Bestandsvertrag sind,
   * werden die Formwerte an den Tarifrechner geschickt.
   */
  private setAenderbareVertragsdatenReise(
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ): AenderbareVertragsdatenReise {
    const aenderbareVertragsdatenReise: AenderbareVertragsdatenReise = {
      reiseAssistanceleistungenInklAuslandsreisekranken: this.determineReiseassistanceValue(
        vertragDetails,
        bearbeitenForm,
      ),
      reiseruecktritt: this.setBeitragsmodelYesNoValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_REISE,
        FormcontrolsEnum.REISERUECKTRITT,
        vertragDetails,
        bearbeitenForm,
      ),
      versicherungssummeReisegepaeck: this.setBeitragsmodelValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_REISE,
        FormcontrolsEnum.VERSICHERUNGSSUMME_REISEGEPAECK,
        vertragDetails,
        bearbeitenForm,
      ),
      versicherungssummeReiseruecktritt: this.setBeitragsmodelValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_REISE,
        FormcontrolsEnum.VERSICHERUNGSSUMME_REISERUECKTRITT,
        vertragDetails,
        bearbeitenForm,
      ),
      reisegepaeck: this.setValuesThatDependOnHausratOptimalXXL(
        FormcontrolsEnum.REISEGEPAECK,
        bearbeitenForm,
        SpartenEnum.AENDERBAREVERTRAGSDATEN_REISE,
        vertragDetails,
      ),
    };

    if (this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISERUECKTRITT).value) !== true) {
      aenderbareVertragsdatenReise.versicherungssummeReiseruecktritt = null;
    }

    if (
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISEGEPAECK).value) !== true ||
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).value) === true
    ) {
      aenderbareVertragsdatenReise.versicherungssummeReisegepaeck = null;
    }

    aenderbareVertragsdatenReise.reise = this.getSparteReiseValue(bearbeitenForm, vertragDetails);

    return this.filterOutChangedValues(aenderbareVertragsdatenReise);
  }

  /**
   * Function delivers true if one of the following attributes true is: reisegepaeck, reiseruecktritt, reiseAssistanceleistungenInklAuslandsreisekranken
   * and sparte reise in Bestandsvertrag is false. Delivers false if none of the attributes is true and sparte Reise in Bestandsvertrag is true. Otherwise delivers null.
   */
  private getSparteReiseValue(bearbeitenForm: UntypedFormGroup, vertragDetails: VertragDetails): boolean {
    const isHausratOptimalXxl = this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).value);
    switch (isHausratOptimalXxl) {
      case true:
        return this.setReiseIfHausratOptimalXXlIsTrue(bearbeitenForm, vertragDetails);
      case false:
        return this.setReiseIfHausratOptimalXXlIsFalse(bearbeitenForm, vertragDetails);
    }
  }

  /**
   * Function to determine Sparte Reise value if HausratOptimalXxl is true
   */

  private setReiseIfHausratOptimalXXlIsTrue(bearbeitenForm: UntypedFormGroup, vertragDetails: VertragDetails): boolean {
    if (
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISERUECKTRITT).value) === true ||
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISEASSISTANCELEISTUNGEN).value) === true
    ) {
      return vertragDetails.vertrag.aenderbareVertragsdatenReise?.reise === true ? null : true;
    }
    return vertragDetails.vertrag.aenderbareVertragsdatenReise?.reise !== true ? null : false;
  }

  /**
   * Function to determine Sparte Reise value if HausratOptimalXxl is false
   */
  private setReiseIfHausratOptimalXXlIsFalse(
    bearbeitenForm: UntypedFormGroup,
    vertragDetails: VertragDetails,
  ): boolean {
    if (
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISEGEPAECK).value) === true ||
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISERUECKTRITT).value) === true ||
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISEASSISTANCELEISTUNGEN).value) === true
    ) {
      return vertragDetails.vertrag.aenderbareVertragsdatenReise?.reise === true ? null : true;
    }
    return vertragDetails.vertrag.aenderbareVertragsdatenReise?.reise !== true ? null : false;
  }

  /**
   * Determines the value of Reiseassistance considering the business logic rules
   *
   * @param vertragDetails
   * @param bearbeitenForm
   * @private
   */
  private determineReiseassistanceValue(
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ): boolean | null {
    if (this.canReiseassistanceBeChanged(bearbeitenForm)) {
      return this.setBeitragsmodelYesNoValue(
        SpartenEnum.AENDERBAREVERTRAGSDATEN_REISE,
        FormcontrolsEnum.REISEASSISTANCELEISTUNGEN,
        vertragDetails,
        bearbeitenForm,
      );
    } else {
      return (
        vertragDetails.vertrag.aenderbareVertragsdatenReise?.reiseAssistanceleistungenInklAuslandsreisekranken ?? null
      );
    }
  }

  /**
   * Business logic rules determining whether Reiseassistance can be changed
   *
   * @param bearbeitenForm
   * @private
   */
  private canReiseassistanceBeChanged(bearbeitenForm: UntypedFormGroup): boolean {
    return (
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).value) ||
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISEGEPAECK).value) ||
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.REISERUECKTRITT).value)
    );
  }

  /**
   * Function to set 'AenderbareVertragsdatenUnfall' array for Tarifrechner data model
   *
   * @param vertragDetails
   * @param bearbeitenForm
   * @private
   */
  private setAenderbareVertragsdatenUnfall(
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ): AenderbareVertragsdatenUnfall[] {
    const unfallArray = bearbeitenForm.get(FormcontrolsEnum.AENDERBAREVERTRAGSDATEN_UNFALL) as UntypedFormArray;
    return vertragDetails.vertrag.aenderbareVertragsdatenUnfall
      .map((unfall, index) => {
        const progression = this.beitragErmittelnService.getProgressionForPerson(unfall.versichertePerson);
        if (progression !== null) {
          let finalObject: AenderbareVertragsdatenUnfall = {
            invaliditaetsGrundsumme: this.getInvaliditaetsgrundsumme(
              unfall,
              unfallArray.at(index) as UntypedFormGroup,
              progression,
            ),
            unfallRente: this.getUnfallRente(unfall, unfallArray.at(index) as UntypedFormGroup),
            unfallRentenvariante: this.getUnfallRentenvariante(unfall, unfallArray.at(index) as UntypedFormGroup),
            gliedertaxe: this.getGliedertaxe(unfall, unfallArray.at(index) as UntypedFormGroup),
          };

          finalObject = this.filterOutChangedValues(finalObject);
          if (finalObject !== null) {
            finalObject.versichertePerson = unfall.versichertePerson;
            return finalObject;
          }
          return null;
        }
      })
      .filter((unfall) => !!unfall);
  }

  /**
   * Get valid Invaiditaetsgrundsumme for final unfall object in setAenderbareVertragsdatenUnfall function
   */
  private getInvaliditaetsgrundsumme(
    unfallBestandsvertrag: AenderbareVertragsdatenUnfall,
    unfallForm: UntypedFormGroup,
    progression: ProgressionEnum,
  ): number {
    const minGrundsummeAllowed = this.angebotBearbeitenService.getGrundsummeMin(progression);
    if (
      unfallForm.get(FormcontrolsEnum.INVALIDITAETSGRUNDSUUME).value === minGrundsummeAllowed ||
      unfallForm.get(FormcontrolsEnum.INVALIDITAETSGRUNDSUUME).value === unfallBestandsvertrag.invaliditaetsGrundsumme
    ) {
      return null;
    }
    return unfallForm.get(FormcontrolsEnum.INVALIDITAETSGRUNDSUUME).value;
  }

  /**
   * Get valid Unfallrente for final unfall object in setAenderbareVertragsdatenUnfall function
   */
  private getUnfallRente(unfallBestandsvertrag: AenderbareVertragsdatenUnfall, unfallForm: UntypedFormGroup): number {
    if (
      this.checkUnfallRenteNOTNullAndMinCase(unfallBestandsvertrag, unfallForm) ||
      this.sindBestandsvertragUndFormUnfallRenteGleich(unfallBestandsvertrag, unfallForm)
    ) {
      return null;
    }
    return unfallForm.get(FormcontrolsEnum.UNFALLRENTE).value;
  }

  /**
   * Function to check the case if UnfallRente in Bestandsvertrag is not null and more than 0;
   * at the same time UnfallRente in Form eguals minimal UnfallRente
   */

  private checkUnfallRenteNOTNullAndMinCase(
    unfallBestandsvertrag: AenderbareVertragsdatenUnfall,
    unfallForm: UntypedFormGroup,
  ) {
    return (
      unfallForm.get(FormcontrolsEnum.UNFALLRENTE).value === UnfallRenteMinMaxEnum.MIN &&
      unfallBestandsvertrag.unfallRente !== null &&
      unfallBestandsvertrag.unfallRente > 0
    );
  }

  /**
   * Function to check if UnfallRente in Form equals UnfallRente in Bestandsvertrag
   */
  private sindBestandsvertragUndFormUnfallRenteGleich(
    unfallBestandsvertrag: AenderbareVertragsdatenUnfall,
    unfallForm: UntypedFormGroup,
  ) {
    return unfallForm.get(FormcontrolsEnum.UNFALLRENTE).value === unfallBestandsvertrag.unfallRente;
  }

  /**
   * Get valid UnfallRentenvariante for final unfall object in setAenderbareVertragsdatenUnfall function
   */
  private getUnfallRentenvariante(unfallBestandsvertrag: AenderbareVertragsdatenUnfall, unfallForm: UntypedFormGroup) {
    const unfallRentenvarianteForm =
      unfallForm.get(FormcontrolsEnum.UNFALLRENTENVARIANTE).value !== null
        ? unfallForm.get(FormcontrolsEnum.UNFALLRENTENVARIANTE).value === "ab 50% 1x, 75% 2x"
          ? Rentenvariante.AB_50_EINMAL_AB_75_ZWEIMAL
          : Rentenvariante.AB_50_EINMAL_AB_75_ZWEIMAL_AB_90_DREIMAL
        : null;
    if (
      unfallForm.get(FormcontrolsEnum.UNFALLRENTE).value === null ||
      unfallForm.get(FormcontrolsEnum.UNFALLRENTE).value === 0 ||
      unfallBestandsvertrag.unfallRentenvariante === unfallRentenvarianteForm
    ) {
      return null;
    }
    return unfallRentenvarianteForm;
  }

  /**
   * Get valid Gliedertaxe for final unfall object in setAenderbareVertragsdatenUnfall function
   */
  private getGliedertaxe(unfallBestandsvertrag: AenderbareVertragsdatenUnfall, unfallForm: UntypedFormGroup) {
    if (
      unfallForm.get(FormcontrolsEnum.GLIEDERTAXE).value === "Standard" ||
      (unfallForm.get(FormcontrolsEnum.GLIEDERTAXE).value === "Verbessert" &&
        unfallBestandsvertrag.gliedertaxe === Gliedertaxe.VERBESSERT)
    ) {
      return null;
    }
    return Gliedertaxe.VERBESSERT;
  }

  private setBeitragsmodelValue(
    sparte: string,
    attribute: string,
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ) {
    const formValue = bearbeitenForm.get(attribute).value;
    return vertragDetails.vertrag[sparte] !== null && vertragDetails.vertrag[sparte] !== undefined
      ? vertragDetails.vertrag[sparte][attribute] === formValue
        ? null
        : formValue
      : formValue === false || formValue === undefined
      ? null
      : formValue;
  }

  private setBeitragsmodelYesNoValue(
    sparte: string,
    attribute: string,
    vertragDetails: VertragDetails,
    bearbeitenForm: UntypedFormGroup,
  ) {
    const formValue = this.yesNoToBoolean(bearbeitenForm.get(attribute).value);
    return vertragDetails.vertrag[sparte] !== null && vertragDetails.vertrag[sparte] !== undefined
      ? vertragDetails.vertrag[sparte][attribute] === formValue
        ? null
        : formValue
      : formValue === false
      ? null
      : formValue;
  }

  /**
   * Falls HausratOptimalXXL von dem Benutzer auf true gesetzt wird, sollen die Attribute, soll der Attribute als false an Tarifrechner geschickt werden.
   * Das betrifft folgende Attribute: Fahrradplus, Freizeitplus, Reisegepaeck
   */
  private setValuesThatDependOnHausratOptimalXXL(
    attribute: string,
    bearbeitenForm: UntypedFormGroup,
    sparte: SpartenEnum,
    vertragDetails: VertragDetails,
  ): boolean {
    const returnValue =
      this.yesNoToBoolean(bearbeitenForm.get(FormcontrolsEnum.HAUSRATOPTIMAL_XXL).value) === true
        ? false
        : this.yesNoToBoolean(bearbeitenForm.get(attribute).value);

    if (vertragDetails.vertrag[sparte] === null) {
      return returnValue === false ? null : returnValue;
    } else {
      return returnValue === vertragDetails.vertrag[sparte][attribute] ? null : returnValue;
    }
  }

  private yesNoToBoolean(val: "yes" | "no"): boolean {
    return val === "yes";
  }
}

export enum SpartenEnum {
  AENDERBAREVERTRAGSDATEN_GLAS = "aenderbareVertragsdatenGlas",
  AENDERBAREVERTRAGSDATEN_HAFTPFLICHT = "aenderbareVertragsdatenHaftpflicht",
  AENDERBAREVERTRAGSDATEN_HAUSRAT = "aenderbareVertragsdatenHausrat",
  AENDERBAREVERTRAGSDATEN_REISE = "aenderbareVertragsdatenReise",
  AENDERBAREVERTRAGSDATEN_UNFALL = "aenderbareVertragsdatenUnfall",
}
