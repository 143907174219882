import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DesignSystemModule } from "@dvag/design-system-angular";
import { CookieModalComponent } from "./cookie-modal/cookie-modal.component";
import { ModalComponent } from "./modal/modal.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { ErrorAlertComponent } from "./error-alert/error-alert.component";

@NgModule({
  declarations: [CookieModalComponent, ModalComponent, ErrorPageComponent, ErrorAlertComponent],
  exports: [CookieModalComponent, ModalComponent, ErrorAlertComponent],
  imports: [CommonModule, DesignSystemModule],
})
export class SharedModule {}
