import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cookie-modal",
  templateUrl: "./cookie-modal.component.html",
  styleUrls: ["./cookie-modal.component.scss"],
})
export class CookieModalComponent implements OnInit {
  showCookieModal: boolean;

  ngOnInit(): void {
    this.showCookieModal = !this.isCookieValueSet("cookieconsent", "allow");
  }

  acceptCookie() {
    this.setCookie("cookieconsent", "allow", 365);
    this.reloadPage();
  }

  private isCookieValueSet(cookieName, cookieValue): boolean {
    let foundCookieAndValue = false;
    const name = cookieName + "=";
    const ca = document.cookie.split(";");
    ca.forEach((cookie) => {
      if (cookie.trim().indexOf(name) === 0 && cookie.indexOf(cookieValue) >= 0) {
        foundCookieAndValue = true;
      }
    });
    return foundCookieAndValue;
  }

  private setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue}; ${expires}`;
  }

  private reloadPage() {
    window.location.reload();
  }
}
