import { Injectable } from "@angular/core";
import { EnvironmentService } from "./environment.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { ZobHauptuser } from "../../dashboard/models/zobHauptUser.model";
import { environment } from "../../../environments/environment";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";
import { KeycloakService } from "./keycloak.service";
import { ZobUser } from "../models/zob-user.model";
import { Vermoegensberater } from "../models/vermoegensberater.model";
import { MandantEnum } from "../models/mandant.enum";

@Injectable({
  providedIn: "root",
})
export class AssistentService {
  private selectedHauptuserId$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private zobHauptbenutzerArray: ZobHauptuser[] = [];
  private zobContextUser: ZobUser;

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private keycloakService: KeycloakService,
  ) {}

  /**
   * Function triggers BE-call to get main user array when user id and type are available
   */
  public loadHauptbenutzerArray(): Observable<ZobHauptuser[]> {
    return combineLatest([
      this.keycloakService.userId$.asObservable(),
      this.keycloakService.benutzerTyp$.asObservable(),
    ]).pipe(
      filter(([userId, benutzertyp]: [string, string]) => {
        return userId.length > 0 && benutzertyp.length > 0;
      }),
      switchMap(([userId, _]: [string, string]) => {
        return this.getHauptnutzer(userId);
      }),
      catchError((_) => of([] as ZobHauptuser[])),
      take(1),
    );
  }

  public getZobContextUser(): ZobUser {
    return this.zobContextUser;
  }

  public getZobHauptbenutzerArray(): ZobHauptuser[] {
    return this.zobHauptbenutzerArray;
  }

  public setZobHauptbenutzerArray(zobHauptBenutzer: ZobHauptuser[]) {
    this.zobHauptbenutzerArray = zobHauptBenutzer;
  }

  public setSelectedHauptbenutzerId(id: string) {
    this.selectedHauptuserId$.next(id);
    if (!this.getIsEigenerBestand()) {
      this.getHauptbenutzerData(id).pipe(take(1)).subscribe(); // todo don't subscribe here!
    }
  }

  public getSelectedHauptbenutzerId$(): Observable<string> {
    return this.selectedHauptuserId$.asObservable();
  }

  public getSelectedHauptbenutzerId(): string {
    return this.selectedHauptuserId$.getValue();
  }

  public getHauptbenutzerData(contextUserId: string): Observable<Vermoegensberater> {
    if (!environment.environment.includes("vdl-")) {
      if (this.zobContextUser && this.zobContextUser.userid === contextUserId) {
        return of(this.mapContextUserToVermoegensberater(this.zobContextUser));
      }

      const reqOptions = {
        headers: new HttpHeaders({
          "context-user": contextUserId,
        }),
      };
      return this.http
        .get<ZobUser>(`${this.environmentService.getZobUrl()}/ressources/users/loggedinusercontextuser`, reqOptions)
        .pipe(
          map((zobUser) => this.mapGesellschaft(zobUser)),
          tap((contextUser) => (this.zobContextUser = contextUser)),
          map((contextUser) => this.mapContextUserToVermoegensberater(contextUser)),
        );
    }
    return of({} as Vermoegensberater); // todo error handling
  }

  public getIsEigenerBestand(): boolean {
    return this.selectedHauptuserId$.getValue().length === 0;
  }

  private getHauptnutzer(userId: string): Observable<ZobHauptuser[]> {
    if (!environment.environment.includes("vdl-")) {
      return this.http.get<ZobHauptuser[]>(`${this.environmentService.getZobUrl()}/ressources/vertretungen/${userId}`);
    }
    return of([]);
  }

  private mapContextUserToVermoegensberater(contextUser: ZobUser): Vermoegensberater {
    return {
      nummer: contextUser.userid,
      vorname: contextUser.vorname,
      nachname: contextUser.name,
      email: contextUser.stammdat.email,
      mandant: contextUser.gesellschaft as MandantEnum,
    };
  }

  private mapGesellschaft(zobUser): ZobUser {
    switch (zobUser.gesellschaft) {
      case "1":
      case "2":
        zobUser.gesellschaft = MandantEnum.DVAG;
        break;
      case "3":
        zobUser.gesellschaft = MandantEnum.ALLFINANZ;
        break;
      case "5":
        zobUser.gesellschaft = MandantEnum.ALLFINANZ_AG;
        break;
      default:
        zobUser.gesellschaft = MandantEnum.DVAG;
    }

    return zobUser as ZobUser;
  }
}
