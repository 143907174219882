export interface AenderbareVertragsdatenReise {
  reise?: boolean;
  reisegepaeck?: boolean;
  versicherungssummeReisegepaeck?: VersicherungssummeReisegepaeckEnum;
  reiseruecktritt?: boolean;
  versicherungssummeReiseruecktritt?: VersicherungssummeReiseruecktrittEnum;
  reiseAssistanceleistungenInklAuslandsreisekranken?: boolean;
}

export enum VersicherungssummeReisegepaeckEnum {
  VERSICHERUNGSSUMME_REISEGEPAECK_2000 = "VERSICHERUNGSSUMME_REISEGEPAECK_2000",
  VERSICHERUNGSSUMME_REISEGEPAECK_3000 = "VERSICHERUNGSSUMME_REISEGEPAECK_3000",
  VERSICHERUNGSSUMME_REISEGEPAECK_4000 = "VERSICHERUNGSSUMME_REISEGEPAECK_4000",
  VERSICHERUNGSSUMME_REISEGEPAECK_5000 = "VERSICHERUNGSSUMME_REISEGEPAECK_5000",
  VERSICHERUNGSSUMME_REISEGEPAECK_6000 = "VERSICHERUNGSSUMME_REISEGEPAECK_6000",
}

export enum VersicherungssummeReiseruecktrittEnum {
  VERSICHERUNGSSUMME_REISERUECKTRITT_2000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_2000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_3000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_3000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_4000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_4000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_5000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_5000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_6000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_6000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_7000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_7000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_8000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_8000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_9000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_9000",
  VERSICHERUNGSSUMME_REISERUECKTRITT_10000 = "VERSICHERUNGSSUMME_REISERUECKTRITT_10000",
}
