import { Component } from "@angular/core";

@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
export class ErrorPageComponent {
  errorMessage = "Ihnen fehlt die Berechtigung diese Anwendung zu verwenden.";
}
