import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UmstellungsfaehigkeitEnum } from "../dashboard/models/vertragsumstellungsfaehigkeitEnum";
import { TextUmstellungsfaehigkeit } from "../dashboard/models/umstellungsfaehigkeit.text.enum";
import { DeckungsauspraegungEnum } from "../dashboard/models/deckungsauspraegungEnum";
import { DsAlert, DsTextInput } from "@dvag/design-system-angular";
import { Vertragsumstellung } from "../dashboard/models/vertragsumstellung";
import { VertraegeService } from "../dashboard/services/vertraege.service";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { VertragdetailsService } from "../shared/services/vertragdetails.service";
import { take, takeUntil, tap } from "rxjs/operators";
import { FormsService } from "../shared/services/forms.service";
import { VertragDetails } from "../dashboard/models/vertragdetails.model";
import { Observable, Subject, throwError } from "rxjs";
import { BeitragErmittelnService } from "../shared/services/beitrag-ermitteln.service";
import { Vertragsumstellungsfaehigkeit } from "../dashboard/models/vertragsumstellungsfaehigkeit";
import { Beitragsberechnung } from "../dashboard/models/beitragsberechnung";
import { VersandwegEnum } from "../dashboard/models/versandwegEnum";
import { BeitragInfo } from "../dashboard/models/beitragInfo";
import { VertragsdatenFuerAngebot } from "../shared/models/vertragsdaten-fuer-angebot";
import { RouteWatchService } from "../shared/services/route-watch.service";
import { VertragsumstellungenService } from "../shared/services/vertragsumstellungen.service";
import { VertragsdatenMappingService } from "../shared/services/vertragsdaten-mapping.service";
import { FormcontrolsEnum } from "../dashboard/models/formcontrols.enum";
import { KommunikationsverbindungenService } from "../shared/services/kommunikationsverbindungen.service";
import { VertrauensmitarbeiterService } from "./vertrauensmitarbeiter.service";
import { Vertrauensmitarbeiter } from "../shared/models/vertrauensmitarbeiter.model";
import { KommunikationsArtEnum } from "../shared/models/KommunikationsArt.enum";
import { KundendatenModel } from "../shared/models/kundendaten.model";
import { TEXTBLOCKS } from "./text-blocks";
import { validateMobilePhoneNumberLength, validateMobilePhoneNumberPattern } from "../shared/custom-validators";
import { KeycloakService } from "../shared/services/keycloak.service";
import { AlertService } from "../shared/services/alert.service";
import { AssistentService } from "../shared/services/assistent.service";
import { UserService } from "../shared/services/user.service";

@Component({
  selector: "app-listeneintrag-details",
  templateUrl: "./vertrag-details.component.html",
  styleUrls: ["./vertrag-details.component.scss"],
})
export class VertragDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild("zobHauptbenutzerAlert") zobHauptbenutzerAlert: DsAlert;
  @ViewChild("anschreibenInput") anschreibenInput: DsTextInput;
  @ViewChild("vertrauensmitarbeiterInput") vertrauensmitarbeiterInput: DsTextInput;
  @ViewChild("zukuenftigerBeitragGeaendert") zukuenftigerBeitragGeaendert: DsAlert;
  showVertragsdetails = false;
  detailsForm: UntypedFormGroup;
  bearbeitenForm: UntypedFormGroup;
  direction: DirectionEnum;
  offeneContracts: Vertragsumstellung[];
  vertragsnummer;
  nextVertragsnummer: string;
  hinweis: Array<string>;
  umstellungsfaehigkeit = UmstellungsfaehigkeitEnum;
  vertragDetails: VertragDetails;
  mehrminderbeitrag: number;
  showMinderbeitragbeseitigen = false;
  geaenderterZukuenftigerBeitrag: number;
  vertragsdatenUnfallNotInitialized: boolean;
  verwerfen = false;
  maxFlexN: number;
  eweText: string;
  isPostAnschreibenModalOpen = false;
  anschreibenBestandteile: string[] = [];

  showFullPageLoading = true;
  loadingBeitragsInfo = true;
  showEweBtn = false;

  beitragsInfoError = false;
  anschreibenFromBackend: string;

  requiredControls: string[];
  errorEMailMessage = "";
  errorTelefonMessage = "";
  showError = false;
  showErrorMessage = false;

  flexNKontostand: number;

  emailDropdown = [];
  mobilfunknummerDropdown = [];

  vertrauensmitarbeiter: Vertrauensmitarbeiter[];
  selektierterVertrauensmitarbeiter: Vertrauensmitarbeiter;

  textBlocks = TEXTBLOCKS;

  eweBtnText: string;
  eweInfoText: string;

  anschreibenEnterKeyPressed = false;

  private destroy$ = new Subject();

  constructor(
    private keycloak: KeycloakService,
    public vertragdetailsService: VertragdetailsService,
    private vertraege: VertraegeService,
    private router: Router,
    private route: ActivatedRoute,
    private vertragsumstellungenService: VertragsumstellungenService,
    public formsService: FormsService,
    private beitragErmittelnService: BeitragErmittelnService,
    private cdRef: ChangeDetectorRef,
    private routeWatchService: RouteWatchService,
    private vertragsdatenMappingService: VertragsdatenMappingService,
    private vertrauensmitarbeiterService: VertrauensmitarbeiterService,
    private kommunikationsverbindungsService: KommunikationsverbindungenService,
    public assistentService: AssistentService,
    private keycloakService: KeycloakService,
    private alertService: AlertService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.routeWatchService.getPreviousUrl().includes("/detailinformationen")) {
      this.handleHauptbenutzer();
    } else {
      this.showVertragsdetails = true;
      this.loadVertragsDetails();
    }
  }

  /**
   * Function to save main user id when page refreshed and the logged in user is "ASSISTENT"
   *
   * @param id is the id of the selected main user
   */
  chooseHauptnutzer(id: string) {
    this.assistentService.setSelectedHauptbenutzerId(id);
    this.loadVertragsDetails();
    this.showVertragsdetails = true;
    document.body.style.overflow = "";
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(); // trigger the unsubscribe
    this.destroy$.complete(); // finalize & clean up the subject stream
  }

  public abortAndNavigateAway() {
    if (this.verwerfen) {
      this.handleNavigationAfterContractDeletion();
    } else if (this.direction === DirectionEnum.FORWARD) {
      this.navigateToNextVertragsumstellung();
    } else {
      this.navigateToDashboard();
    }
  }

  public checkFormValidation(versandweg: string) {
    if (versandweg === VersandwegEnum.EMAIL) {
      this.validateVersandwegEmail();
    } else {
      this.validateVersandwegPost();
    }
    this.updateRequiredControls();
  }

  public checkIfContractCanBeSent() {
    if (this.vertragDetails?.vertrag.zukuenftigerBeitrag === 0) {
      this.alertService.openVersandVerhindernNichtBerechenbarAlert();
    } else if (this.mehrminderbeitrag < -120) {
      this.alertService.openVersandVerhindernMinderbeitragAlert();
    } else if (
      this.detailsForm.invalid &&
      this.vertragDetails?.vertrag.umstellungsfaehigkeit !== UmstellungsfaehigkeitEnum.NICHT
    ) {
      this.alertService.openPflichtfelderAusfüllenAlert();
      this.showErrorMessage = true;
      this.showError = true;
    } else if (this.vertragDetails?.vertrag.umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.NICHT) {
      this.openErsatzantragImDOS();
    } else {
      this.showEweOrVersendenAlert();
    }
  }

  public closeAnschreibenModalDiscardChanges() {
    this.isPostAnschreibenModalOpen = false;
  }

  public setAnschreibenKeyUp() {
    this.anschreibenEnterKeyPressed = false;
  }

  public checkAnschreibenChangeAllowed(event: KeyboardEvent): boolean {
    const isMetaKey =
      event.code === "Backspace" ||
      event.metaKey ||
      event.altKey ||
      event.ctrlKey ||
      event.shiftKey ||
      event.code === "ArrowLeft" ||
      event.code === "ArrowUp" ||
      event.code === "ArrowRight" ||
      event.code === "ArrowDown";
    if (this.anschreibenEnterKeyPressed === true) {
      return false;
    } else if (event.code === "Enter") {
      this.anschreibenEnterKeyPressed = true;
    }
    if (isMetaKey) {
      return true;
    } else {
      const lastLineLength = this.anschreibenInput.value.split("\n").reverse()[0].length;
      const remainingLines = this.calculateAvailableAnschreibenLines(this.anschreibenInput.value);
      return (
        remainingLines > 0 ||
        (remainingLines === 0 && event.code !== "Enter" && (lastLineLength === 0 || lastLineLength % 54 !== 0))
      );
    }
  }

  public calculateAvailableAnschreibenLines(anschreiben: string): number {
    let lineCount = 0;
    if (anschreiben !== undefined) {
      const lines = anschreiben.split("\n");
      lineCount = lines.length;
      lines.forEach((value) => {
        lineCount = lineCount + this.countIndirectLines(value);
      });
    }
    return 66 - lineCount;
  }

  public countIndirectLines(lineContent: string): number {
    let increment = 0;
    if (lineContent.length > 54) {
      increment++;
      increment = increment + this.countIndirectLines(this.splitStringAfterLineEnding(lineContent, 54));
    }
    return increment;
  }

  public splitStringAfterLineEnding(content: string, lineEnding: number) {
    const regularLineValue = content.substr(0, lineEnding);
    const blankCut = regularLineValue.includes(" ") ? regularLineValue.lastIndexOf(" ") : lineEnding;
    const hyphenCut = regularLineValue.includes("-") ? regularLineValue.lastIndexOf("-") : lineEnding;
    const cutPoint = blankCut >= hyphenCut ? blankCut : hyphenCut;
    return content.substr(cutPoint + 1);
  }

  public closeAnschreibenModalSaveChanges(anschreiben: string) {
    this.detailsForm.patchValue({ anschreiben });
    this.isPostAnschreibenModalOpen = false;
    this.detailsForm.markAsTouched();
    this.detailsForm.get(FormcontrolsEnum.ANSCHREIBEN).markAsTouched();
  }

  public confirmVersenden() {
    this.showFullPageLoading = true;
    this.loadingBeitragsInfo = true;
    const beitragsberechnung = this.vertragsdatenMappingService.mapToBeitragsberechnung(
      this.bearbeitenForm,
      this.formsService.getDetailsForm(),
      this.vertragDetails,
    );

    this.beitragErmittelnService
      .fetchBeitragInfo(this.vertragsnummer, beitragsberechnung)
      .pipe(take(1))
      .subscribe(
        (beitragInfo: BeitragInfo) => {
          this.loadingBeitragsInfo = false;
          if (
            this.vertragDetails.vertrag.zukuenftigerBeitrag !== beitragInfo.zukuenftigerBeitrag &&
            beitragInfo.zukuenftigerBeitrag !== 0
          ) {
            this.geaenderterZukuenftigerBeitrag = beitragInfo.zukuenftigerBeitrag;
            this.zukuenftigerBeitragGeaendert.visible = true;
            this.showFullPageLoading = false;
          } else {
            this.handleBeitragInfo(beitragInfo);
            this.versendeAngebot();
          }
        },
        () => {
          this.loadingBeitragsInfo = false;
          this.beitragsInfoError = true;
          this.showFullPageLoading = false;
        },
      );
  }

  public eweEinwilligen() {
    this.showFullPageLoading = true;
    const versicherungsnehmer = this.vertragDetails.vertrag.versicherungsnehmer;
    const postleizahlUndOrt = versicherungsnehmer.ort.split(" ");
    const kundendaten: KundendatenModel = {
      kundenId: versicherungsnehmer.kundenId,
      kundennummer: versicherungsnehmer.kundennummer,
      vorname: versicherungsnehmer.vorname,
      nachname: versicherungsnehmer.name,
      geburtsdatum: versicherungsnehmer.geburtsdatum,
      strasse: versicherungsnehmer.strasse,
      plz: postleizahlUndOrt[0],
      ort: postleizahlUndOrt[1],
      email: this.detailsForm.get(FormcontrolsEnum.EMAIL).value,
      telefon: this.detailsForm.get(FormcontrolsEnum.TELEFON).value,
    };
    this.vertragdetailsService
      .postEinwilligungserklaerung(kundendaten)
      .then(() => {
        this.alertService.openEweVersendenErfolgreichAlert({
          okAction: this.navigateToNextVertragsumstellung.bind(this),
          abortAction: this.navigateBack.bind(this),
        });
        this.showFullPageLoading = false;
      })
      .catch(() => {
        this.showFullPageLoading = false;
      });
  }

  public sendNewEMailToBE() {
    const kommunikationsverbindungen = {
      nummer: this.detailsForm.get(FormcontrolsEnum.EMAIL).value,
      art: KommunikationsArtEnum.EMAIL,
    };
    if (this.detailsForm.controls.email.invalid) {
      this.errorEMailMessage = this.textBlocks.error.email;
      this.showError = true;
    } else {
      this.showError = false;
      this.errorEMailMessage = "";
      const filterEmailValue = this.emailDropdown.filter(
        (email) => email.value === this.detailsForm.get(FormcontrolsEnum.EMAIL).value,
      );
      if (filterEmailValue.length <= 0) {
        this.kommunikationsverbindungsService
          .addKommunikationsverbindungen(
            this.vertragDetails.vertrag?.versicherungsnehmer.kundennummer,
            kommunikationsverbindungen,
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }
    }
  }

  public sendNewTelefonToBE() {
    const kommunikationsverbindungen = {
      nummer: this.detailsForm.get(FormcontrolsEnum.TELEFON).value,
      art: KommunikationsArtEnum.TELEFON,
    };
    if (this.detailsForm.invalid && this.detailsForm.controls.telefon.invalid) {
      this.errorTelefonMessage = this.textBlocks.error.telefon;
      this.showError = true;
    } else {
      this.showError = false;
      this.errorTelefonMessage = "";
      const filterTelefonValue = this.mobilfunknummerDropdown.filter(
        (tel) => tel.value === this.detailsForm.get(FormcontrolsEnum.TELEFON).value,
      );
      if (filterTelefonValue.length <= 0) {
        this.kommunikationsverbindungsService
          .addKommunikationsverbindungen(
            this.vertragDetails.vertrag.versicherungsnehmer.kundennummer,
            kommunikationsverbindungen,
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }
    }
  }

  public flexNfocusOut(flexN: number) {
    if (flexN === null || flexN < 0 || Number.isNaN(flexN)) {
      this.detailsForm.get(FormcontrolsEnum.FLEX_N).patchValue(0);
    }
    this.ifCurrentFlexNIsGreaterThanMaxFlexN(flexN);
    this.getBeitragsInfo(false);
  }

  public formatDeckungsauspraegung(sparte: DeckungsauspraegungEnum) {
    if (sparte !== null) {
      return sparte.toString().charAt(0) + sparte.toString().toLowerCase().slice(1);
    } else {
      return "";
    }
  }

  public getTextForVersandVerhindernAlert() {
    if (this.mehrminderbeitrag < -120 && this.vertragDetails?.vertrag.zukuenftigerBeitrag !== 0) {
      return "Der Versand des Umstellungsangebotes ist nicht möglich. Der Minderbeitrag übersteigt 120 €. Daher kann der Vertrag nicht umgestellt werden";
    } else {
      return "Der Versand des Umstellungsangebotes ist nicht möglich, da kein Beitrag berechnet werden konnte. Bitte beachten Sie die Hinweise zur Bearbeitung";
    }
  }

  public getTrafficLight() {
    switch (this.vertragDetails.vertrag.umstellungsfaehigkeit) {
      case UmstellungsfaehigkeitEnum.AUTOMATISCH:
        return "traffic-light-green";
      case UmstellungsfaehigkeitEnum.TEILWEISE:
        return "traffic-light-yellow";
      case UmstellungsfaehigkeitEnum.NICHT:
        return "traffic-light-red";
      default:
        console.log("error - no umstellungsfaehigkeit");
        break;
    }
  }

  public getUmstellungsfaehigkeit(): TextUmstellungsfaehigkeit {
    switch (this.vertragDetails.vertrag.umstellungsfaehigkeit) {
      case this.umstellungsfaehigkeit.AUTOMATISCH:
        return TextUmstellungsfaehigkeit.AUTOMATISCH;
      case this.umstellungsfaehigkeit.TEILWEISE:
        return TextUmstellungsfaehigkeit.BEDINGT;
      case this.umstellungsfaehigkeit.NICHT:
        return TextUmstellungsfaehigkeit.NICHT;
    }
  }

  public getVersendenButtonText(): string {
    if (this.vertragDetails?.vertrag.umstellungsfaehigkeit === UmstellungsfaehigkeitEnum.NICHT) {
      return "Ersatzantrag stellen";
    } else {
      return "Versenden";
    }
  }

  public getVertragsnummerNext(): string {
    if (this.nextVertragsnummer) {
      /**
       * this.nextVertragsnummer will only be set in versendeAngebot() to capture the next contract number
       * before the current contract gets removed from the list
       */
      return this.nextVertragsnummer;
    } else {
      try {
        const index = this.offeneContracts.findIndex(
          (contract) => contract?.vertrag.vertragsnummer === this.vertragsnummer,
        );
        const vertrag = this.offeneContracts[index + 1];
        return vertrag.vertrag.vertragsnummer;
      } catch (e) {
        return null;
      }
    }
  }

  public hasNextVertrag(): boolean {
    return this.getVertragsnummerNext() && this.getVertragsnummerNext() !== this.vertragDetails?.vertrag.vertragsnummer;
  }

  public ifCurrentFlexNIsGreaterThanMaxFlexN(flexN: number) {
    if (flexN > this.maxFlexN) {
      this.alertService.openMaxFlexNUeberschrittenAlert();
      this.detailsForm.get(FormcontrolsEnum.FLEX_N).patchValue(this.maxFlexN);
    }
  }

  public isFormControlRequired(controlName: string): boolean {
    return this.requiredControls?.includes(controlName);
  }

  public navigateBack() {
    if (
      this.detailsForm.get(FormcontrolsEnum.ANSCHREIBEN).touched ||
      this.detailsForm.get(FormcontrolsEnum.FLEX_N).touched ||
      this.formsService.getAngebotBearbeitenForms().touched
    ) {
      this.direction = DirectionEnum.BACK;
      this.showDiscardChangesAlert();
    } else {
      this.vertragdetailsService.reset();
      this.detailsForm.reset();
      this.navigateToDashboard();
    }
  }

  public navigateNext() {
    if (
      this.detailsForm.get(FormcontrolsEnum.ANSCHREIBEN).touched ||
      this.detailsForm.get(FormcontrolsEnum.FLEX_N).touched ||
      this.formsService.getAngebotBearbeitenForms().touched
    ) {
      this.direction = DirectionEnum.FORWARD;
      this.showDiscardChangesAlert();
    } else if (this.vertragsnummer !== this.getVertragsnummerNext()) {
      this.vertragdetailsService.reset();
      this.detailsForm.reset();
      this.navigateToNextVertragsumstellung();
    }
  }

  public navigateToDashboard(): void {
    this.resetChanges();
    this.router.navigate([""]);
  }

  public navigateToNextVertragsumstellung(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    const vertragNrNext = this.getVertragsnummerNext();
    this.resetChanges();
    if (vertragNrNext) {
      this.router.navigate(["/detailinformationen", { vertrag: vertragNrNext }]);
    } else {
      this.router.navigate(["/"]);
    }
    this.nextVertragsnummer = null;
  }

  public openAngebotBearbeiten() {
    this.router.navigate(["angebotbearbeiten"]);
  }

  public openAnscheibenBearbeiten() {
    const beitragsBerechnung = this.vertragsdatenMappingService.mapToBeitragsberechnung(
      this.bearbeitenForm,
      this.formsService.getDetailsForm(),
      this.vertragDetails,
      /* TODO wurde der Minderbeitrag beseitigt? */ false,
    );

    if (!this.detailsForm.get("anschreiben").value) {
      this.showFullPageLoading = true;
      this.vertragsumstellungenService
        .getPostAnschreiben(
          this.vertragsnummer,
          beitragsBerechnung.aenderbareVertragsdatenReise,
          beitragsBerechnung.aenderbareVertragsdatenHausrat,
        )
        .pipe(take(1))
        .subscribe(
          (anschreiben) => {
            this.anschreibenFromBackend = anschreiben.bestandteile[1];
            this.detailsForm.patchValue({ anschreiben: anschreiben.bestandteile[1] });
            this.fillAndOpenAnschreibenModal();
          },
          () => {
            this.showFullPageLoading = false;
          },
          () => {
            this.showFullPageLoading = false;
          },
        );
    } else {
      this.fillAndOpenAnschreibenModal();
      this.showFullPageLoading = false;
    }
  }

  public openBestandsvertragImDOS() {
    let url = "";
    const kundennummer = this.vertragDetails.vertrag.versicherungsnehmer.kundennummer;
    const angemeldeteVBnummer = this.keycloakService.userId$.getValue();
    if (this.keycloak.benutzerTyp$.getValue() === "ASSISTENT" || !!this.assistentService.getSelectedHauptbenutzerId()) {
      const hauptVBnummer = this.assistentService.getSelectedHauptbenutzerId();
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&cu=${hauptVBnummer}&ja=ki&j=GlobalAkteVertrag.eigenvertrag-buendel&kundenNummer=${kundennummer}&vertragsNummer=${this.vertragsnummer}`;
    } else {
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&ja=ki&j=GlobalAkteVertrag.eigenvertrag-buendel&kundenNummer=${kundennummer}&vertragsNummer=${this.vertragsnummer}`;
    }
    this.aufrufDOS(url);
  }

  public openErsatzantragImDOS() {
    let url = "";
    const angemeldeteVBnummer = this.keycloakService.userId$.getValue();
    const kundennummer = this.vertragDetails.vertrag.versicherungsnehmer.kundennummer;
    const vertragsnummer = this.vertragDetails.vertrag.vertragsnummer;
    const haushaltId = this.vertragDetails.vertrag.versicherungsnehmer.haushaltsId;
    if (this.keycloak.benutzerTyp$.getValue() === "ASSISTENT" || !!this.assistentService.getSelectedHauptbenutzerId()) {
      const hauptVBnummer = this.assistentService.getSelectedHauptbenutzerId();
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&cu=${hauptVBnummer}&ja=ki&j=Vermoegensplanung.vertragsanpassung&haushaltsId=${haushaltId}&kundenNummer=${kundennummer}&vertragsNummer=${vertragsnummer}&vpProduktId=SUH&vpAppId=VP`;
    } else {
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&ja=ki&j=Vermoegensplanung.vertragsanpassung&haushaltsId=${haushaltId}&kundenNummer=${kundennummer}&vertragsNummer=${vertragsnummer}&vpProduktId=SUH&vpAppId=VP`;
    }
    this.aufrufDOS(url);
  }

  public openEweVersendenAlert() {
    this.alertService.openEweVersendenAlert(this.detailsForm.get(FormcontrolsEnum.EMAIL).value, {
      okAction: this.eweEinwilligen.bind(this),
    });
  }

  public openKundenAkteImDOS() {
    let url = "";
    const angemeldeteVBnummer = this.keycloakService.userId$.getValue();
    const kundennummer = this.vertragDetails.vertrag.versicherungsnehmer.kundennummer;
    if (this.keycloak.benutzerTyp$.getValue() === "ASSISTENT" || !!this.assistentService.getSelectedHauptbenutzerId()) {
      const hauptVBnummer = this.assistentService.getSelectedHauptbenutzerId();
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&cu=${hauptVBnummer}&ja=ki&j=GlobalAktePerson.kundennummer&kundennummer=${kundennummer}`;
    } else {
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&ja=ki&j=GlobalAktePerson.kundennummer&kundennummer=${kundennummer}`;
    }
    this.aufrufDOS(url);
  }

  public zumKontostand() {
    let url = "";
    const angemeldeteVBnummer = this.keycloak.userId$.getValue();
    if (this.keycloak.benutzerTyp$.getValue() === "ASSISTENT" || !!this.assistentService.getSelectedHauptbenutzerId()) {
      const hauptVBnummer = this.assistentService.getSelectedHauptbenutzerId();
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&cu=${hauptVBnummer}&ja=vbi&j=VBI.report&RPBERICHTNR=330&RPKONTOART_4=VSP&rpVBNr_10=${hauptVBnummer}`;
    } else {
      url = `dvagsc://linkparams?u=${angemeldeteVBnummer}&ja=vbi&j=VBI.report&RPBERICHTNR=330&RPKONTOART_4=VSP&rpVBNr_10=${angemeldeteVBnummer}`;
    }
    this.aufrufDOS(url);
  }

  public openLeistungsvergleich() {
    this.vertragsumstellungenService
      .fetchLeistungsvergleichPdf(this.mapAngebotDaten())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {},
        (error) => {
          throwError(error);
        },
      );
  }

  public openVorschau() {
    this.vertragsumstellungenService
      .fetchAnschreibenVorschauPdf(this.mapAngebotDaten())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {},
        (error) => {
          console.error("PDF ERROR", error);
        },
      );
  }

  public showEweOrVersendenAlert() {
    if (this.hasEweMissing()) {
      this.alertService.openEwePostAlert(this.detailsForm.get(FormcontrolsEnum.EMAIL).value, {
        okAction: this.eweEinwilligen.bind(this),
      });
    } else if (this.detailsForm.get(FormcontrolsEnum.VERSANDWEG).value === "EMAIL" && this.hasEwePartial()) {
      this.alertService.openEweEmailAlert(this.detailsForm.get(FormcontrolsEnum.EMAIL).value, {
        okAction: this.eweEinwilligen.bind(this),
      });
    } else {
      if (this.detailsForm.get(FormcontrolsEnum.VERSANDWEG).value === "POST") {
        this.alertService.openAngebotVersendenPostAlert({ okAction: this.confirmVersenden.bind(this) });
      } else {
        this.alertService.openAngebotVersendenEmailAlert(
          this.detailsForm.get(FormcontrolsEnum.EMAIL).value,
          this.detailsForm.get(FormcontrolsEnum.TELEFON).value,
          { okAction: this.confirmVersenden.bind(this) },
        );
      }
    }
  }

  public onAutoComplete(event) {
    if (event.detail.value?.length >= 3) {
      const userInput = event.detail.value.toLowerCase();

      const suggestions = this.vertrauensmitarbeiter
        .filter((vm) => {
          return (
            vm.name.toLowerCase().startsWith(userInput) ||
            vm.vorname.toLowerCase().startsWith(userInput) ||
            vm.nummer.startsWith(userInput)
          );
        })
        .map((vm) => {
          return {
            label: `${vm.name}, ${vm.vorname} ${vm.nummer}`,
            value: `${vm.name}, ${vm.vorname} ${vm.nummer}`,
            data: vm,
          };
        });

      suggestions.sort((a, b) => a.label.localeCompare(b.label));
      event.detail.provideValues(suggestions);
    } else {
      event.detail.provideValues([]);
    }
  }

  public onSelecetAutoComplete(event) {
    this.selektierterVertrauensmitarbeiter = event.detail as Vertrauensmitarbeiter;
  }

  public onFocusoutVertrauensmitarbeiter() {
    if (this.vertrauensmitarbeiterInput.value.match(RegExp("[A-Za-zÄöÖüÜ\\W ]+, [A-Za-zÄöÖüÜ\\W ]+ [0-9]+"))) {
      const name = this.vertrauensmitarbeiterInput.value.split(",")[0].trim();

      const vornameUndVbNummer = this.vertrauensmitarbeiterInput.value.split(",")[1].trim();

      const vorname = vornameUndVbNummer.substring(0, vornameUndVbNummer.lastIndexOf(" "));
      const nummer = vornameUndVbNummer.substring(vornameUndVbNummer.lastIndexOf(" ")).trim();
      const vertrauensmitarbeiterGefiltert = this.vertrauensmitarbeiter.filter(
        (mitarbeiter) => mitarbeiter.nummer === nummer && mitarbeiter.name === name && mitarbeiter.vorname === vorname,
      );
      if (vertrauensmitarbeiterGefiltert.length >= 1) {
        if (vertrauensmitarbeiterGefiltert[0] !== this.selektierterVertrauensmitarbeiter) {
          this.selektierterVertrauensmitarbeiter = vertrauensmitarbeiterGefiltert[0];
        }
        return;
      }
    }
    this.selektierterVertrauensmitarbeiter = null;
    this.vertrauensmitarbeiterInput.value = "";
  }

  public showCorrectEWEText(): string {
    if (this.hasEweFull()) {
      this.showEweBtn = false;
      this.eweText = "Vorhanden - mit E-Mail-Werbeeinwilligung";
      return this.boldOneWord(this.eweText, "mit");
    } else if (this.hasEwePartial()) {
      this.showEweBtn = this.userService.canSendEwe();
      this.eweText = "Vorhanden - ohne E-Mail-Werbeeinwilligung";
      this.eweBtnText = "EWE erneut einholen";
      this.eweInfoText = this.textBlocks.info.ewePartial;
      return this.boldOneWord(this.eweText, "ohne");
    } else if (this.hasEweMissing()) {
      this.showEweBtn = this.userService.canSendEwe();
      this.eweText = "Nicht vorhanden";
      this.eweBtnText = "EWE einholen";
      this.eweInfoText = this.textBlocks.info.eweMissing;
      return this.boldOneWord(this.eweText, "Nein");
    }
  }

  public showMinderbeitragAlert() {
    this.alertService.openMindereitragBeseitigenAlert({ okAction: this.minderbeitragBeseitigen.bind(this) });
  }

  public showUmstellungVerwerfenAlert() {
    this.alertService.openUmstellungVerwerfenAlert({ okAction: this.umstellungVerwerfen.bind(this) });
  }

  public umstellungVerwerfen() {
    this.nextVertragsnummer = this.getVertragsnummerNext();
    this.formsService.resetForm();
    this.vertragdetailsService.reset();
    this.vertraege.setUmstellungsstatusToVerworfen(this.vertragsnummer);
    if (this.vertragsnummer === this.getVertragsnummerNext()) {
      // if there is a problem with an open AngebotVerwerfenAlert, close it here (somehow)
      this.navigateBack();
    } else {
      this.openAfterAngebotVerwerfenAlert();
    }
  }

  public versendeAngebot() {
    this.showFullPageLoading = true;
    this.nextVertragsnummer = this.getVertragsnummerNext();
    this.vertraege
      .versendeAngebot(this.mapAngebotDaten())
      .then((result) => {
        if (result === true) {
          const btnActions = {
            okAction: this.navigateToNextVertragsumstellung.bind(this),
            abortAction: this.navigateToDashboard.bind(this),
          };
          if (this.detailsForm.get(FormcontrolsEnum.VERSANDWEG).value === VersandwegEnum.EMAIL) {
            this.alertService.openAngebotVersendetEmailAlert(btnActions);
          } else {
            this.alertService.openAngebotVersendetPostAlert(btnActions);
          }
          this.showFullPageLoading = false;
        }
      })
      .catch((e) => {
        console.error(e);
        this.showFullPageLoading = false;
      });
  }

  public getTextForversendenSuccessfulAlert(): { title: string; body: string } {
    return this.detailsForm.get(FormcontrolsEnum.VERSANDWEG).value === VersandwegEnum.EMAIL
      ? this.textBlocks.alert.angebotVersendetEmail
      : this.textBlocks.alert.angebotVersendetPost;
  }

  /**
   * Function for calculating Beitrag
   *
   * @param minderbeitragBeseitigen false per default, with button click 'Minderbeitrag beseitigen' true
   */
  public getBeitragsInfo(minderbeitragBeseitigen: boolean) {
    const beitragsBerechnung = this.vertragsdatenMappingService.mapToBeitragsberechnung(
      this.bearbeitenForm,
      this.formsService.getDetailsForm(),
      this.vertragDetails,
      minderbeitragBeseitigen,
    );
    this.beitragErmittelnService.setBeitragsBerechnung(beitragsBerechnung);
    this.loadingBeitragsInfo = true;
    this.beitragErmittelnService
      .fetchBeitragInfo(this.vertragsnummer, beitragsBerechnung)
      .pipe(take(1))
      .subscribe(
        (beitragInfo: BeitragInfo) => {
          this.loadingBeitragsInfo = false;
          if (this.vertragsdatenUnfallNotInitialized) {
            this.initVeraenderbareVertragsdatenUnfall();
          }
          this.handleBeitragInfo(beitragInfo);
        },
        () => {
          this.loadingBeitragsInfo = false;
          this.beitragsInfoError = true;
        },
      );
  }

  // PRIVATE

  /**
   * Function handles the situation when the reload of the details page is triggered. If the logged in user type === ASSISTENT, the user is suggested to choose the main user
   *
   * @private
   */
  private handleHauptbenutzer() {
    this.assistentService.loadHauptbenutzerArray().subscribe((hauptnutzerArray) => {
      this.assistentService.setZobHauptbenutzerArray(hauptnutzerArray);
      if (
        this.keycloakService.benutzerTyp$.getValue() === "ASSISTENT" &&
        !this.assistentService.getSelectedHauptbenutzerId()
      ) {
        this.zobHauptbenutzerAlert.visible = true;
      } else {
        this.showVertragsdetails = true;
        this.loadVertragsDetails();
      }
    });
  }

  private async loadVertragsDetails() {
    this.vertraege
      .getOffeneVertragsumstellungen$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((offeneVertragsumstellungen) => {
        this.offeneContracts = offeneVertragsumstellungen;
      });
    this.route.paramMap.pipe(take(1)).subscribe((params) => {
      this.vertragsnummer = params.get("vertrag");
      if (
        this.vertragsnummer !== this.vertragdetailsService.getVertragsnummer() ||
        this.routeWatchService.getPreviousUrl() === "/"
      ) {
        this.vertragdetailsService.setVertragsnummer(this.vertragsnummer);
        this.getDetails(this.vertragsnummer);
      } else {
        this.vertragDetails = this.vertragdetailsService.getVertragsdetails();
        // eslint-disable-next-line sonarjs/no-duplicate-string
        if (this.routeWatchService.getPreviousUrl() === "/angebotbearbeiten") {
          this.getBeitragsInfo(false);
        }
        this.showFullPageLoading = false;
        this.getKommunikationsverbindungenFromVertragsdetails();
      }
    });
    this.vertragdetailsService
      .getKontostandNachlasskonto()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result.length > 0) {
          this.flexNKontostand = result.find((kontostandObject) => kontostandObject.typ === "FLEXN").kontostand;
        }
      });
    this.vertrauensmitarbeiter = await this.vertrauensmitarbeiterService.ermittleVertrauensmitarbeiter();
  }

  private aufrufDOS(dosUrl: string) {
    if (navigator.platform.includes("Win")) {
      window.open(dosUrl);
    } else {
      this.alertService.openDosNotInstalledAlert();
    }
  }

  private boldOneWord(searchText: string, replaceText: string): string {
    const strRegExp = new RegExp(replaceText, "g");
    return searchText.replace(strRegExp, "<b>" + replaceText + "</b>");
  }

  private fillAndOpenAnschreibenModal() {
    this.anschreibenBestandteile = this.vertragsumstellungenService.getAnschreiben().bestandteile;
    this.anschreibenInput.value = this.detailsForm.get(FormcontrolsEnum.ANSCHREIBEN).value;
    this.isPostAnschreibenModalOpen = true;
  }

  /**
   * Function to calculate initial Beitrag when component is generated
   */
  private initialBeitragsBerechnung() {
    const beitragsberechnung: Beitragsberechnung = {
      minderbeitragBeseitigen: false,
    };
    this.beitragErmittelnService.setBeitragsBerechnung(beitragsberechnung);
    this.loadingBeitragsInfo = true;
    this.beitragErmittelnService
      .fetchBeitragInfo(this.vertragsnummer, beitragsberechnung)
      .pipe(take(1))
      .subscribe(
        (beitragInfo: BeitragInfo) => {
          this.loadingBeitragsInfo = false;
          this.vertragsdatenUnfallNotInitialized = beitragInfo.zukuenftigerBeitrag === 0;
          this.initVeraenderbareVertragsdatenUnfall();
          this.handleBeitragInfo(beitragInfo);
        },
        () => {
          this.loadingBeitragsInfo = false;
          this.beitragsInfoError = true;
        },
      );
  }

  private getDetails(vertragsnummer: string) {
    this.vertragdetailsService
      .getDetails(vertragsnummer)
      .pipe(take(1))
      .subscribe(
        (vertragdetails) => {
          this.vertragDetails = vertragdetails;
          this.updateUmstellungsfaehigkeit(vertragdetails.vertrag.umstellungsfaehigkeit, this.vertragsnummer)
            .pipe(takeUntil(this.destroy$))
            .subscribe((vertragsumstellungsfaehigkeit: Vertragsumstellungsfaehigkeit) => {
              if (vertragsumstellungsfaehigkeit.umstellungsfaehigkeit !== UmstellungsfaehigkeitEnum.NICHT) {
                this.initialBeitragsBerechnung();
              } else {
                this.loadingBeitragsInfo = false;
              }
            });
          this.formsService.setFormValues(this.vertragDetails);
          this.showFullPageLoading = false;
          this.getKommunikationsverbindungenFromVertragsdetails();
        },
        () => {
          // to end loading-spinner animation on error
          this.showFullPageLoading = false;
        },
      );
  }

  private getKommunikationsverbindungenFromVertragsdetails() {
    const emails = this.vertragDetails.vertrag?.versicherungsnehmer.emails;
    emails?.forEach((email) => this.emailDropdown.push({ value: email, label: email }));
    const telefonnummern = this.vertragDetails.vertrag?.versicherungsnehmer.telefonnummern;
    telefonnummern?.forEach((telefon) => this.mobilfunknummerDropdown.push({ value: telefon, label: telefon }));
  }

  private handleBeitragInfo(beitragInfo: BeitragInfo): void {
    this.vertragDetails.vertrag.zukuenftigerBeitrag = beitragInfo.zukuenftigerBeitrag;
    this.vertragDetails.vertrag.hinweiseZurBearbeitung = beitragInfo.hinweise?.length > 0 ? beitragInfo.hinweise : [];

    if (beitragInfo.zukuenftigerBeitrag !== 0) {
      this.mehrminderbeitrag =
        this.vertragDetails?.vertrag.zukuenftigerBeitrag +
        beitragInfo.aktuellerFlexn -
        this.vertragDetails?.vertrag.aktuellerBeitrag;
      this.maxFlexN = beitragInfo.maximalerFlexn;
      this.showMinderbeitragbeseitigen = this.mehrminderbeitrag < 0;

      const aktualisierteVertragDetails = JSON.parse(JSON.stringify(this.vertragDetails));
      for (const unfallPerson of beitragInfo.unfallversichertePersonen) {
        for (const unfallDaten of aktualisierteVertragDetails.vertrag.aenderbareVertragsdatenUnfall) {
          if (
            unfallPerson.vorname === unfallDaten.versichertePerson.vorname &&
            unfallPerson.name === unfallDaten.versichertePerson.name
          ) {
            unfallDaten.invaliditaetsGrundsumme = unfallPerson.invaliditaetsgrundsumme;
          }
        }
      }
      this.detailsForm.patchValue({
        flexN: beitragInfo.aktuellerFlexn.toString(),
      });
      this.formsService.patchInvaliditaetsgrundsummen(
        aktualisierteVertragDetails.vertrag.aenderbareVertragsdatenUnfall,
      );
    }
    this.cdRef.detectChanges();
  }

  private hasEweFull(): boolean {
    return (
      this.vertragDetails?.vertrag.versicherungsnehmer.einwilligungErteilt === true &&
      this.vertragDetails?.vertrag.versicherungsnehmer.werbungPerMailErlaubt === true
    );
  }

  private hasEweMissing(): boolean {
    return this.vertragDetails?.vertrag.versicherungsnehmer.einwilligungErteilt === false;
  }

  private hasEwePartial(): boolean {
    return (
      this.vertragDetails?.vertrag.versicherungsnehmer.einwilligungErteilt === true &&
      this.vertragDetails?.vertrag.versicherungsnehmer.werbungPerMailErlaubt === false
    );
  }

  private initForm() {
    this.detailsForm = this.formsService.getDetailsForm();
    this.bearbeitenForm = this.formsService.getAngebotBearbeitenForms();
    this.updateRequiredControls();
  }

  private mapAngebotDaten(): VertragsdatenFuerAngebot {
    const beitragsberechnung = this.vertragsdatenMappingService.mapToBeitragsberechnung(
      this.bearbeitenForm,
      this.formsService.getDetailsForm(),
      this.vertragDetails,
      false,
    );
    return {
      vertragsnummer: this.vertragsnummer,
      flexn: this.detailsForm.get(FormcontrolsEnum.FLEX_N).value ?? 0,
      minderbeitragBeseitigen: false,
      email: this.detailsForm.get(FormcontrolsEnum.EMAIL).value,
      telefonnummer: this.detailsForm.get(FormcontrolsEnum.TELEFON).value,
      aenderbareVertragsdatenGlas: beitragsberechnung.aenderbareVertragsdatenGlas,
      aenderbareVertragsdatenHaftpflicht: beitragsberechnung.aenderbareVertragsdatenHaftpflicht,
      aenderbareVertragsdatenHausrat: beitragsberechnung.aenderbareVertragsdatenHausrat,
      aenderbareVertragsdatenReise: beitragsberechnung.aenderbareVertragsdatenReise,
      aenderbareVertragsdatenUnfall: beitragsberechnung.aenderbareVertragsdatenUnfall,
      versandweg: this.detailsForm.get(FormcontrolsEnum.VERSANDWEG).value,
      personalisiertesAnschreiben:
        this.detailsForm.get(FormcontrolsEnum.ANSCHREIBEN).value === this.anschreibenFromBackend
          ? null
          : this.detailsForm.get(FormcontrolsEnum.ANSCHREIBEN).value,
      vertrauensmann:
        this.detailsForm.get(FormcontrolsEnum.VERTRAUENSMITARBEITERIN).value !== ""
          ? this.selektierterVertrauensmitarbeiter
          : null,
    } as VertragsdatenFuerAngebot;
  }

  private minderbeitragBeseitigen() {
    this.getBeitragsInfo(true);
  }

  private resetChanges() {
    this.detailsForm.reset();
    this.bearbeitenForm.reset();
    this.formsService.resetForm();
    this.vertragdetailsService.reset();
  }

  private showDiscardChangesAlert() {
    this.alertService.openBearbeitenAbbrechenAlert({ okAction: this.abortAndNavigateAway.bind(this) });
  }

  private updateRequiredControls() {
    this.requiredControls = this.formsService.getRequiredControls(this.detailsForm);
  }

  private updateUmstellungsfaehigkeit(
    bestandsvertragUmstellungsfaehigkeit: UmstellungsfaehigkeitEnum,
    vertragNr: string,
  ): Observable<Vertragsumstellungsfaehigkeit> {
    return this.vertraege.updateUmstellungsfaehigkeit(vertragNr, bestandsvertragUmstellungsfaehigkeit).pipe(
      take(1),
      tap((res: Vertragsumstellungsfaehigkeit) => {
        this.vertragDetails.vertrag.umstellungsfaehigkeit = res.umstellungsfaehigkeit;
        this.hinweis = res.umstellungshinweise;
      }),
    );
  }

  /**
   * Function is triggered if the user wants to delete the contract and discard the changes made. Function handles the async problem: only after the contract has been marked as "Verworfen", the user can process with navigation
   */
  private handleNavigationAfterContractDeletion() {
    this.vertraege.setUmstellungsstatusToVerworfen(this.vertragsnummer);
    this.vertraege
      .getContractDeleted()
      .pipe(takeUntil(this.destroy$))
      .subscribe((deleted) => {
        if (deleted) {
          this.openAfterAngebotVerwerfenAlert();
        }
      });
  }

  /**
   * Function to trigger alert for user to choose the navigation way: to dashboard or to the next contract
   */
  private openAfterAngebotVerwerfenAlert() {
    setTimeout(() => {
      this.alertService.openAfterAngebotVerwerfenAlert({
        okAction: this.navigateToNextVertragsumstellung.bind(this),
        abortAction: this.navigateBack.bind(this),
      });
    }, 200);
  }

  private initVeraenderbareVertragsdatenUnfall() {
    if (this.beitragErmittelnService.getCurrentBeitragsInfo()?.zukuenftigerBeitrag !== 0) {
      this.formsService.setaenderbareVertragsdatenUnfall(this.vertragDetails);
      this.vertragsdatenUnfallNotInitialized = false;
    }
  }

  private validateVersandwegEmail() {
    this.detailsForm.controls.email.setValidators([Validators.required, Validators.email]);
    this.detailsForm.controls.email.updateValueAndValidity();
    this.detailsForm.controls.telefon.setValidators([
      Validators.required,
      validateMobilePhoneNumberLength(),
      validateMobilePhoneNumberPattern(),
    ]);
    this.detailsForm.controls.telefon.updateValueAndValidity();
  }

  private validateVersandwegPost() {
    this.detailsForm.get(FormcontrolsEnum.EMAIL).clearValidators();
    this.detailsForm.get(FormcontrolsEnum.TELEFON).clearValidators();
    this.detailsForm.controls.email.updateValueAndValidity();
    this.detailsForm.controls.telefon.updateValueAndValidity();
  }
}

export enum DirectionEnum {
  BACK = "back",
  FORWARD = "forward",
}
