import { Component, Input } from "@angular/core";
import { LeistungsuebersichtUnfall } from "../../shared/models/leistungsuebersicht-unfall.interface";
import { Angebotsart } from "../../shared/models/angebotsart.enum";
import { LeistungsuebersichtRowData } from "./leistungsuebersicht-row-data.interface";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-leistungsuebersicht",
  templateUrl: "./leistungsuebersicht.component.html",
  styleUrls: ["./leistungsuebersicht.component.scss"],
})
export class LeistungsuebersichtComponent {
  @Input()
  get leistungsuebersicht(): LeistungsuebersichtUnfall[] {
    return this._leistungsuebersicht;
  }
  set leistungsuebersicht(leistungsuebersichtUnfall: LeistungsuebersichtUnfall[]) {
    this._leistungsuebersicht = leistungsuebersichtUnfall;
    if (leistungsuebersichtUnfall) {
      this.leistungsuebersichtRow = this.mapColumnToRowOrientedData(leistungsuebersichtUnfall);
    }
  }
  leistungsuebersichtLabel = [
    { title: "Angebotsart", key: "angebotsart" },
    { title: "Invaliditätsgrundsumme", key: "invaliditaetsgrundsumme" },
    { title: "Verbesserte Gliedertaxe", key: "verbesserteGliedertaxe" },
    { title: "Todesfallleistung", key: "todesfallleistung" },
    { title: "Erw. Krankenhaustagegeld mit Verdopplung", key: "krankenhaustagesgeld" },
    { title: "Verbesserte Übergangsleistungen", key: "verbesserteUebergangsleistung" },
    { title: "Fixes Schmerzensgeld bei Knochenbrüchen", key: "fixesSchmerzensgeldBeiKnochenbruch" },
    { title: "Gestaffeltes Schmerzensgeld bei Knochenbrüchen", key: "gestaffeltesSchmerzensgeldBeiKnochenbruch" },
    { title: "Sofortleistung", key: "sofortleistung" },
    { title: "Unfall-Rente", key: "unfallRente" },
    { title: "Genesungsgeld", key: "genesungsgeld" },
    { title: "Schmerzensgeld bei Bänderrissen", key: "schmerzensgeld" },
    { title: "Assistance XXL", key: "assistanceXxl" },
  ];
  leistungsuebersichtRow: LeistungsuebersichtRowData = {
    angebotsart: null,
    assistanceXxl: null,
    fixesSchmerzensgeldBeiKnochenbruch: null,
    genesungsgeld: null,
    gestaffeltesSchmerzensgeldBeiKnochenbruch: null,
    invaliditaetsgrundsumme: null,
    krankenhaustagesgeld: null,
    schmerzensgeld: null,
    sofortleistung: null,
    todesfallleistung: null,
    unfallRente: null,
    verbesserteGliedertaxe: null,
    verbesserteUebergangsleistung: null,
    zahlungInvaliditaet: null,
  };
  private _leistungsuebersicht: LeistungsuebersichtUnfall[];

  constructor(private decimalPipe: DecimalPipe) {}

  private mapColumnToRowOrientedData(
    leistungsuebersichtColumn: LeistungsuebersichtUnfall[],
  ): LeistungsuebersichtRowData {
    const leistungsuebersichtRow: LeistungsuebersichtRowData = {} as LeistungsuebersichtRowData;
    leistungsuebersichtColumn.forEach((leistungsuebersicht) => {
      this.leistungsuebersichtLabel.forEach((entry) => {
        let valueToPush;
        if (entry.key === "angebotsart") {
          valueToPush = leistungsuebersicht[entry.key] === Angebotsart.FREI ? "Frei" : "UNFALL AKTIV";
        } else if (typeof leistungsuebersicht[entry.key] === "boolean") {
          valueToPush = leistungsuebersicht[entry.key] ? "Ja" : "Nein";
        } else if (typeof leistungsuebersicht[entry.key] === "number") {
          valueToPush = this.decimalPipe.transform(leistungsuebersicht[entry.key], "1.0-0");
        } else if (entry.key === "verbesserteGliedertaxe" && !leistungsuebersicht[entry.key]) {
          valueToPush = "Nein";
        } else {
          valueToPush = leistungsuebersicht[entry.key];
        }
        leistungsuebersichtRow[entry.key] = leistungsuebersichtRow[entry.key] ?? [];
        leistungsuebersichtRow[entry.key].push(valueToPush);
      });
    });

    return leistungsuebersichtRow;
  }
}
