import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvironmentService } from "./environment.service";
import { Observable } from "rxjs";
import { BeitragInfo, UnfallversichertePerson } from "../../dashboard/models/beitragInfo";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { Beitragsberechnung } from "../../dashboard/models/beitragsberechnung";
import { VersichertePerson } from "../../dashboard/models/aenderbareVertragsdatenUnfall";
import { KeycloakService } from "./keycloak.service";
import { ProgressionEnum } from "../../dashboard/models/progression.enum";
import { GefahrengruppenEnum } from "../../dashboard/models/gefahrengruppen.enum";

@Injectable({
  providedIn: "root",
})
export class BeitragErmittelnService {
  public beitragsberechnung: Beitragsberechnung;
  private currentBeitragsInfo: BeitragInfo;

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService,
    private keycloak: KeycloakService,
  ) {}

  public fetchBeitragInfo(vertragsnummer: string, beitragsberechnung?: Beitragsberechnung): Observable<BeitragInfo> {
    return this.keycloak.getMandant().pipe(
      take(1),
      mergeMap((mandant) => {
        const httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        };
        const body = { mandant, ...beitragsberechnung };
        return this.httpClient
          .post(`${this.environment.getBackendUrl()}/vertraege/${vertragsnummer}/berechnungen`, body, httpOptions)
          .pipe(
            map((res) => res as BeitragInfo),
            tap((beitragsInfo) => {
              if (beitragsInfo.zukuenftigerBeitrag === 0 && this.currentBeitragsInfo) {
                // no zukuenftigerBeitrag after changes in KI shouldn't override other data, esp. unfalldaten -> progression / gfg
                this.currentBeitragsInfo.zukuenftigerBeitrag = beitragsInfo.zukuenftigerBeitrag;
                this.currentBeitragsInfo.hinweise = beitragsInfo.hinweise;
              } else {
                this.currentBeitragsInfo = beitragsInfo;
              }
            }),
          );
      }),
    );
  }

  public getCurrentBeitragsInfo(): BeitragInfo {
    if (this.currentBeitragsInfo) {
      return this.currentBeitragsInfo;
    } else {
      throw Error("No current BeitragsInfo");
    }
  }

  public setBeitragsBerechnung(beitragsBerechnung) {
    this.beitragsberechnung = beitragsBerechnung;
  }

  public getBeitragsberechnung() {
    return this.beitragsberechnung;
  }

  public getProgressionAndGfgForPerson(versichertePerson: VersichertePerson): [ProgressionEnum, GefahrengruppenEnum] {
    const personWithProgressionAndGfg = this.findVersichertePersonInUnfallversichertePersonen(
      versichertePerson,
      this.currentBeitragsInfo.unfallversichertePersonen,
    );
    if (personWithProgressionAndGfg) {
      return [personWithProgressionAndGfg.progression, personWithProgressionAndGfg.gefahrengruppe];
    } else {
      throw Error("Could not find matching Progression and Gefahrengruppe");
    }
  }

  public getProgressionForPerson(versichertePerson: VersichertePerson): ProgressionEnum {
    const personWithProgression = this.findVersichertePersonInUnfallversichertePersonen(
      versichertePerson,
      this.currentBeitragsInfo.unfallversichertePersonen,
    );
    if (personWithProgression) {
      return personWithProgression.progression;
    } else {
      return null;
    }
  }

  private findVersichertePersonInUnfallversichertePersonen(
    versichertePerson: VersichertePerson,
    unfallversichertePersonen,
  ): UnfallversichertePerson {
    return unfallversichertePersonen.find((person) => {
      return (
        person.vorname === versichertePerson.vorname &&
        person.name === versichertePerson.name &&
        person.geburtsdatum === versichertePerson.geburtsdatum
      );
    });
  }
}
