import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParameterCodec, HttpParams } from "@angular/common/http";
import { CustomHttpParameterCodec } from "./encoder";

@Injectable({
  providedIn: "root",
})
export class HttpHelperService {
  private encoder: HttpParameterCodec = new CustomHttpParameterCodec();

  public addToHttpParams(httpParams: HttpParams, value: string | number, key: string): HttpParams {
    value = typeof value === "number" ? `${value}` : value;
    return httpParams.append(key, value);
  }

  public newHttpParams(): HttpParams {
    return new HttpParams({ encoder: this.encoder });
  }

  public newHttpHeader(key?: string, value?: string): HttpHeaders {
    const headers = new HttpHeaders();
    if (key && value) {
      return headers.set(key, value);
    }
    return headers;
  }
}
