/**
 * suh-bestandsmanagement
 * Mittels dieser Schnittstelle wird der Bestand der Sach-, Unfall und Haftpflichtverträge gepflegt und verwaltet.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface VertragsumstellungsStatusEnum {
  /**
   * Die Status der Vertragsumstellung
   */
  umstellungsstatus?: UmstellungsstatusEnum;
}
export enum UmstellungsstatusEnum {
  OFFEN = "OFFEN",
  VERWORFEN = "VERWORFEN",
  VERSENDET = "VERSENDET",
  ABGELAUFEN = "ABGELAUFEN",
  ANGENOMMEN = "ANGENOMMEN",
  WARTE_AUF_UEBERTRAGUNG = "WARTE_AUF_UEBERTRAGUNG",
  WARTE_AUF_UNTERSCHRIFT = "WARTE_AUF_UNTERSCHRIFT",
  LEER = "LEER",
}
