import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ErrorHandlerService } from "../services/error-handler.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.handleError(error.error);
        return throwError(error);
      }),
    );
  }
}
