import { BrowserModule } from "@angular/platform-browser";
import {
  APP_INITIALIZER,
  ApplicationRef,
  DEFAULT_CURRENCY_CODE,
  DoBootstrap,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { AppInitService } from "./app-init.service";
import { AppComponent } from "./app.component";
import localeDe from "@angular/common/locales/de";
import { DecimalPipe, registerLocaleData } from "@angular/common";
import { httpInterceptorProviders } from "./shared/interceptor/http-interceptors";
import { KeycloakService } from "./shared/services/keycloak.service";
import { DesignSystemModule } from "@dvag/design-system-angular";
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { MoreMenuComponent } from "./more-menu/more-menu.component";
import { NutzungsbedingungenComponent } from "./more-menu/nutzungsbedingungen/nutzungsbedingungen.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngebotBearbeitenComponent } from "./angebot-bearbeiten/angebot-bearbeiten.component";
import { VertragDetailsComponent } from "./vertrag-details/vertrag-details.component";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { LeistungsuebersichtComponent } from "./angebot-bearbeiten/leistungsuebersicht/leistungsuebersicht.component";

registerLocaleData(localeDe, "de-DE");

export function initApp(initService: AppInitService) {
  return () => initService.init();
}

const keycloakService = new KeycloakService();

@NgModule({
  declarations: [
    AppComponent,
    MoreMenuComponent,
    NutzungsbedingungenComponent,
    AngebotBearbeitenComponent,
    VertragDetailsComponent,
    LeistungsuebersichtComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    DesignSystemModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
  ],
  providers: [
    DecimalPipe,
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [AppInitService],
    },
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "EUR" }, // so that currency pipe uses € by default
    {
      provide: LOCALE_ID,
      useValue: "de-DE",
    },
  ],
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(appRef: ApplicationRef) {
    keycloakService.initKeycloak().then(() => {
      appRef.bootstrap(AppComponent);
    });
  }
}
